import React, { useEffect, useState } from 'react';
import ContainerPage from '../_Common/ContainerPage';
import { Translate } from '../../../utils/lang/translate';
import Card from '../_Common/Card/Card';
import { RequestStatus, OrderDetails, ExchangeRefund2Submit} from '../../../types';
import { alertActions, exchangeActions, ordersActions } from '../../../actions';
import { connect } from 'react-redux';
import { Link, RouterChildContext, useHistory, useParams } from 'react-router-dom';
import { CustomerInfoForm } from './components/CustomerInfoForm';
import { DeliveryDetailsForm } from './components/DeliveryDetailsForm';
import Stepper, { StepType } from './components/Stepper';
import { ExchangeProductsForm } from './components/ExchangeProductsForm';
import { Event } from '../_Common';

type AddOrderProps = {
  SendAlert: (code: string, text: string, action: string) => void,
  dataProfile: any,
  dtStoreinfo: any,
  GettingOrderById: RequestStatus,
  orderData: OrderDetails,
  GetOrder: (order_id: string) => void,
  ExchangeAdded: RequestStatus ,
  AddExchange: (data: ExchangeRefund2Submit, is_premium: boolean) => void,
  dataAddExchange: OrderDetails,
  ClearExchangeData: () => void
};

const AddExchange = ({
  dataProfile,
  dtStoreinfo,
  SendAlert,
  GetOrder,
  GettingOrderById,
  orderData,
  AddExchange,
  ExchangeAdded,
  dataAddExchange,
  ClearExchangeData
}: AddOrderProps) => {

  const INITIAL_ORDER: ExchangeRefund2Submit = {
    commune: null,
    customer_name: null,
    customer_phone: null,
    customer_phone2: null,
    destination_text: null,
    products: [],
    total_price: null,
    express: false,
    pickup_point: null,
    note_to_driver: null,
    delivery_type: 1,
    source: 1,
    use_points: false,
    wilaya: null,
    is_exchange: false,
    old_order: null,
  }

  const history = useHistory<RouterChildContext['router']['history']>();

  const { orderId } = useParams<{ orderId: string }>();
  
  const [order, setOrder] = useState<ExchangeRefund2Submit>(INITIAL_ORDER);

  const [currentStep, setCurrentStep] = useState<StepType>("PRODUCT_DETAILS");
  
  useEffect(() => GetOrder(orderId), [orderId]);

  useEffect(() => {

    if(GettingOrderById === '2') {
      setOrder({
        commune: parseInt(`${orderData.commune}`),
        customer_name: orderData.customer_name,
        customer_phone: orderData.customer_phone,
        customer_phone2: orderData.customer_phone2,
        delivery_type: orderData.delivery_type,
        destination_text: orderData.destination_text,
        express: orderData.express ?? false,
        pickup_point: orderData.pickup_point,
        note_to_driver: orderData.note_to_driver,
        products: [...orderData.products],
        source: orderData.source,
        total_price: "0",
        use_points: orderData.use_points,
        wilaya: parseInt(orderData.wilaya_id ?? "0"),
        is_exchange: orderData.is_exchange,
        old_order: orderData.id
      });
    };

    if(GettingOrderById === '3') {
      SendAlert("50", "Failed to fetch order data!", "");
    };
    
  }, [GettingOrderById]);

  useEffect(() => {

    if(ExchangeAdded === "2") {
      SendAlert("41", "Successfuly added order exchange!", "");
      history.push(`/orders?display_id=${dataAddExchange.display_id}`);
    }
    else if(ExchangeAdded === "3") {
      SendAlert("50", "Failed to create order exchange!", "");
    };

  }, [ExchangeAdded]);

  useEffect(() => {
    return ClearExchangeData;
  }, []);

  return (
    <ContainerPage 
      page_title={
        <div className="StDrc">
          <Link className="StOpcVal" to={"/orders"}> {/** needs to point to /orders + source filter */}
            {Translate("titles", "order")}
          </Link>
          <div className="Inlflx StWdDst">
            {Translate("orders", "flech")}
          </div>
          <div className="Inlflx">{Translate("orders", "echangeorder")}</div>
          <div className="Inlflx StWdDst">#</div>
          <div className="Inlflx">{orderData?.display_id ?? ""}</div>
        </div>
      }
    >
      {
        GettingOrderById === '1'
        ?
          <div className='InFlx AlgnItm JstfCnt' style={{ minHeight: "600px" }}>
            <div className='custom-spinner-loader'/>
          </div>
        :
          <Card style={{ gap: "30px" }}>
            
            <Stepper currentStep={currentStep} onStepChange={setCurrentStep}/>

            <ExchangeProductsForm 
              active={currentStep === 'PRODUCT_DETAILS'}
              orderProductsData={
                {
                  orderProducts : GettingOrderById === '2' 
                  ? orderData.products.map(
                    (item, _) => ({ 
                      product_id: item.product_id, 
                      logistical_description: item.logistical_description ?? "", 
                      quantity: item.quantity,
                      is_exchange: item.is_exchange,
                      defective: item.defective ?? false,
                    })
                  ) : null,
                }
              }
              onFormSubmit={
                (data) => {
                  setOrder(prev => ({ ...prev, products: data.products }));
                  setCurrentStep('CLIENT_INFO');
                }
              } 
            />

            <CustomerInfoForm
              active={currentStep === 'CLIENT_INFO'}
              onGoback={() => setCurrentStep('PRODUCT_DETAILS')}
              customerInfo={
                (
                  order.customer_name && 
                  order.customer_phone &&
                  order.wilaya &&
                  order.commune
                )
                ?
                {
                  name: order.customer_name,
                  phone: order.customer_phone,
                  wilaya: order.wilaya,
                  commune: order.commune,
                  second_phone: order.customer_phone2
                }
                : null
              }
              onFormSubmit={
                (data) => {
                  setOrder(prev => ({ 
                    ...prev, 
                    customer_name: data.name, 
                    customer_phone: data.phone,
                    wilaya: data.wilaya,
                    commune: data.commune,
                    customer_phone2: data.second_phone 
                  }));
                  setCurrentStep('DELIVERY_DETAIL');
                }
              }
            />
        
            <DeliveryDetailsForm
              active={currentStep === 'DELIVERY_DETAIL'}
              onGoback={() => setCurrentStep('CLIENT_INFO')}
              onFormSubmit={(data) => {
                Event("ADD_EXCHANGE_PAGE", "ADD_EXCHANGE", "CLICK_EVENT")
                AddExchange({ ...order, ...data, is_exchange: true }, dtStoreinfo.stock_managed);
              }}
              loading={ExchangeAdded === '1'}
              orderDeliveryDetails={{
                ...order,
                wilaya: order.wilaya as number,
                commune: order.commune as number
              }}
              is_exchange={true}
            />
            
          </Card>
      }
    </ContainerPage>
  );
}
 
function mapState(state: any) {

  const { dataProfile, dtStoreinfo } = state.user;
  const { GettingOrderById, orderData } = state.orders;
  const { ExchangeAdded, dataAddExchange } = state.exchanges;

  return {
    dataProfile,
    dtStoreinfo,
    GettingOrderById,
    orderData,
    ExchangeAdded,
    dataAddExchange
  };
}

const actionCreators = {
  SendAlert: alertActions.SendAlert,
  GetOrder: ordersActions.GetOrder,
  AddExchange: exchangeActions.AddExchange,
  ClearExchangeData: exchangeActions.ClearExchangeData,
};

const connectedAddExchange = connect(mapState, actionCreators)(AddExchange);

export { connectedAddExchange as AddExchange };
 
