import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Translate } from '../../../../utils/lang/translate';
import { FlechDwnSvg } from '../IconSvg';

interface CustomSelectProps<T, K extends keyof T> {
  label?: string,
  className?: string,
  containerStyles?: React.CSSProperties,
  optional?: boolean,
  withSearch?: boolean,
  placeholder: string,
  loading: boolean,
  readOnly?: boolean,
  options: T[],
  field2Show: K,
  error?: string | null,
  value: T | null,
  maxDropHeight?: string,
  handler: (object: T | null, text: string) => void,
  renderOption?: (option: T) => ReactElement,
  notice?: string
}

const CustomSelect = <T, K extends keyof T>({
  label,
  className,
  containerStyles,
  optional,
  withSearch,
  placeholder,
  loading,
  readOnly,
  error,
  value,
  field2Show,
  maxDropHeight,
  options,
  handler,
  renderOption,
  notice
}: CustomSelectProps<T, K>) => {

  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");

  const filteredOptions: T[] = 
    loading || 
    options.length === 0
    ?
      []
    :
      options.filter(
        (option, _) => (option[field2Show] as string).toLowerCase().indexOf(search.toLowerCase()) !== -1
      )
  ;

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const new_value = e.target.value;
    const option = options.find(
      option => (option[field2Show] as string).toLowerCase() === new_value.toLowerCase()
    );
    if(option){
      handler(option, option[field2Show] as string);
      setSearch(option[field2Show] as string);
      handleToggle();
    }else{
      handler(null, new_value);
      setSearch(new_value);
      setShowMenu(true);
    }
  };

  const handleChange = (option: T) => {
    setSearch(option[field2Show] as string);
    handleToggle();
    handler(option, option[field2Show] as string);
    searchInputRef.current?.focus();
  };

  const handleClickOutsideMenu = (e: MouseEvent) => {
    if(
      menuRef?.current &&
      !menuRef.current.contains(e.target as Node) &&
      showMenu 
    ){
      setShowMenu(false);
      document.removeEventListener("mouseup", handleClickOutsideMenu);
    }
  }

  const handleToggle = () => {
    if(showMenu){
      document.removeEventListener("mouseup", handleClickOutsideMenu);
    }else{
      document.addEventListener("mouseup", handleClickOutsideMenu);
    }
    setShowMenu(prev => !prev);
  };

  useEffect(() => {
    if(!loading && value) {
      setSearch(value[field2Show] as string);
    }
  }, [value, loading]);


  return (
    <div 
      className={`FlWd InFlx Stclmnf${className ? ` ${className}` : ""}`}
      style={{ gap: "12px", ...containerStyles }}
    >
      {
        label
        &&
        <label>
          {label}
          {optional &&  <span>{Translate("shop", "optional")}</span> }
        </label>
      }
      <div 
        ref={menuRef}
        className={`FlWd RlPs StBrdRdXS${error ? " borderError": ""}`} 
        style={{ backgroundColor: "var(--bdgclInp)" }}
      >
        <div className="InFlx spcBtwn Pdn_select">
          {
            withSearch
            ?
              <input
                className="stfSize FlWd"
                ref={searchInputRef}
                type='text'
                placeholder={loading ? Translate("alert", "load") : placeholder}
                readOnly={readOnly}
                onChange={onSearchChange}
                autoComplete="off"
                value={search}
              />
            :
              <>
                { 
                  value
                  ?  
                    <span>{value[field2Show] as string}</span>  
                  :
                    <span className="StOpcVal">{placeholder}</span>  
                }
              </>
          }
          <i
            className="InFlx CrsPoi rotateToggle"
            onClick={handleToggle}
            style={{ transform: showMenu ? "rotate(0deg)" : "rotate(-90deg)" }}
          >
            {FlechDwnSvg}
          </i>
        </div>
        {
          showMenu
          && 
          <div 
            className="custom-select-dropdown stPdn_btm" 
            style={maxDropHeight ? { maxHeight: maxDropHeight } : undefined}
          >
            {filteredOptions.map((option, index) => (
              <div 
                key={index}
                className="Pdn_select withHvr"
                onClick={() => handleChange(option)}
              >
                {
                  renderOption
                  ?
                    renderOption(option)
                  :
                    option[field2Show] as string
                }
              </div>
            ))}
          </div>
        }
      </div>
      {
        error
        ?
          <div className="FlWd StRedClr" style={{ textAlign: "start" }}>
            {error}
          </div>
        :
          notice && <div className="FlWd StOpcVal StSmlS" style={{ textAlign: "start" }}>{notice}</div>
      }
    </div>
  );
}
 
export default CustomSelect;