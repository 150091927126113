import React, { useEffect, useRef, useState } from "react";
import TabShow from "../_Common/TabShow";
import BtnLg from "../_Common/BtnLg";
import { NextArrowSvg, PreviousSvg } from "../_Common/IconSvg";
import { Translate } from "../../../utils/lang/translate";
import CheckBoxForm from "../../_Common/CheckBoxForm";
interface ExchangeGuideModalProps {
  onClose: (neverShowAgain: boolean) => void,
};

const ExchangeGuideModal = ({
  onClose
}: ExchangeGuideModalProps) => {

  const images = [
    "/assets/img/exchange_guide_modal_04.png",
    "/assets/img/exchange_guide_modal_03.png",
    "/assets/img/exchange_guide_modal_01.png",
    "/assets/img/exchange_guide_modal_02.png",
  ];

  const titles = [
    Translate("exchanges", "exchangeguidetitle1"),
    Translate("exchanges", "exchangeguidetitle2"),
    Translate("exchanges", "exchangeguidetitle3"),
    Translate("exchanges", "exchangeguidetitle4")
  ];

  const contents = [
    FirstModal,
    SecondModal,
    ThirdModal,
    FourthModal
  ];

  const modalRef = useRef<HTMLDivElement | null>(null);
  
  const [current, setCurrent] = useState(0);
  const [neverShow, setNeverShow] = useState(false);
  
  const CurrentModalContent = contents[current];

  const handleClickOutside = (e: MouseEvent) => {
    if(modalRef.current && !modalRef.current?.contains(e.target as Node)){
      onClose(neverShow);
    }
  };

  const handleNextButtonClick = () => {
    if(current < images.length - 1){
      setCurrent(prev => prev + 1);
    }else{
      onClose(neverShow);
    }
  };

  const handlePrevButtonClick = () => {
    if(current > 0){
      setCurrent(prev => prev - 1);
    }
  };

  useEffect(() => {
    document.addEventListener('mouseup', handleClickOutside);
    return () => document.removeEventListener('mouseup', handleClickOutside);
  }, []);

  return (
    <TabShow
      darkBg
      noReturn
      noPadding={true}
      style={{
        minWidth: "60vw",
        borderRadius: "8px",
        overflow: "hidden"
      }}
    >
      <div ref={modalRef} className="FlHg InFlx">
        <div style={{ width: "50%" }}>
          <img className="FlWd FlHg" src={images[current]} alt="..." />
        </div>
        <div className="InFlx AlgnItm JstfCnt" style={{ width: "50%" }}>
          <div className="InFlx Stclmnf align-items-start" style={{ gap: "20px", padding: "0px 32px" }}>
            <h1 
              className="DlMg TxtSt" 
              style={{ fontSize: "32px", fontWeight: 700 }}
            >
              {titles[current]}
            </h1>
            <CurrentModalContent/>
            <div className={`FlWd InFlx AlgnItm ${current === 0 ? "StFlxStr" : "spctwB"}`}>
              {
                current > 0
                &&
                <div
                  className="Inlflx AlgnItm JstfCnt CrsPoi"
                  onClick={handlePrevButtonClick}
                >
                  <BtnLg
                    icon={PreviousSvg}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px 7px"
                    }}
                    text={Translate("exchanges", "previous")} 
                  />
                </div>
              }
              <div
                className="Inlflx AlgnItm JstfCnt CrsPoi"
                onClick={handleNextButtonClick}
              >
                <BtnLg
                  reverseRow
                  icon={current === images.length - 1 ? null : NextArrowSvg}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "10px 7px"
                  }} 
                  text={current === images.length - 1 ? Translate("exchanges", "done") : Translate("exchanges", "next")} 
                />
              </div>
            </div>
            {
              current === images.length - 1
              &&
              <div className="FlWd InFlx">
                <CheckBoxForm
                  id="show-exchange-guide-modal-checkbox"
                  name="show-exchange-guide-modal-checkbox"
                  labelClass="stfSize"
                  text={Translate("exchanges", "dontshowthisagain")}
                  workfun={() => setNeverShow(prev => !prev)}
                  check={neverShow}
                />
              </div>
            }
          </div>
        </div>
      </div>
    </TabShow>
  );
}
 
export default ExchangeGuideModal;

const FirstModal = () => {
  return (
    <p 
      className="DlMg TxtSt"
      style={{ fontSize: "16px", fontWeight: 400 }}
    >
      {Translate("exchanges", "exchangeguidemodal1")}
    </p>
  );
};

const SecondModal = () => {
  return (
    <ul 
      className="DlPg FlWd InFlx Stclmnf align-items-start" 
      style={{ listStyle: "none", gap: "10px" }}
    >
      <ListItem>{Translate("exchanges", "exchangeguidemodal2item1")}</ListItem>
      <ListItem>{Translate("exchanges", "exchangeguidemodal2item2")}</ListItem>
      <ListItem>{Translate("exchanges", "exchangeguidemodal2item3")}</ListItem>
      <ListItem>{Translate("exchanges", "exchangeguidemodal2item4")}</ListItem>
    </ul>
  );
};

const ThirdModal = () => {
  return (
    <ul 
      className="DlPg FlWd InFlx Stclmnf align-items-start" 
      style={{ listStyle: "none", gap: "10px" }}
    >
      <ListItem>{Translate("exchanges", "exchangeguidemodal3item1part1")}<span className="stbold">{Translate("exchanges", "exchangeguidemodal3item1part2")}</span>{Translate("exchanges", "exchangeguidemodal3item1part3")}</ListItem>
      <ListItem>{Translate("exchanges", "exchangeguidemodal3item2")}</ListItem>
    </ul>
  );
};

const FourthModal = () => {
  return (
    <ul 
      className="DlPg FlWd InFlx Stclmnf align-items-start" 
      style={{ listStyle: "none", gap: "10px" }}
    >
      <ListItem>{Translate("exchanges", "exchangeguidemodal3item1part1")}<span className="stbold">{Translate("exchanges", "exchangeguidemodal3item1part2")}</span> {Translate("exchanges", "exchangeguidemodal4item1part3")}</ListItem>
      <ListItem>{Translate("exchanges", "exchangeguidemodal4item2")}</ListItem>
    </ul>
  );
};

interface ListItemProps {
  children: React.ReactNode
}

const ListItem = ({ children }: ListItemProps) => {
  return (
    <li
        className="DlMg TxtSt"
        style={{ fontSize: "16px", fontWeight: 400 }}
    >
      { children }
    </li>
  );
};
