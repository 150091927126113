import React from 'react';
import { Translate } from '../../../../utils/lang/translate';
import PersonIcon from '../../../../svgs/PersonIcon';
import ProductIcon from '../../../../svgs/ProductIcon';
import DeliveryAgent from '../../../../svgs/DeliveryAgent';
import { Event } from '../../_Common';

export type StepType = "PRODUCT_DETAILS" | "CLIENT_INFO" | "DELIVERY_DETAIL";

interface StepperProps {
  currentStep: StepType,
  onStepChange: (to: StepType) => void,
}

const Stepper = ({
  currentStep,
  onStepChange
}: StepperProps) => {

  const steps: Record<StepType, { text: string, icon: (props: React.SVGAttributes<SVGElement>) => React.ReactElement }> = {
    PRODUCT_DETAILS: {
      text: Translate("product", "productdetails"),
      icon: ProductIcon,
    },
    CLIENT_INFO: {
      text: Translate("orders", "clientinfo"),
      icon: PersonIcon,
    },
    DELIVERY_DETAIL: {
      text: Translate("orders", "deliverydetails"),
      icon: DeliveryAgent,
    }
  };

  const currentStepIndex = Object.keys(steps).findIndex((key, _) => key === currentStep);
  const isLastStep = (index: number) => index === Object.keys(steps).length - 1;

  return (
    <div className="InFlx AlgnItm JstfCnt">
      {
        Object.keys(steps).map((key, index) => {
          const Icon = steps[key as StepType].icon;
          return (
            <>
              <div 
                key={index}
                className={`InFlx Stclmnf AlgnItm ${index <= currentStepIndex ? "CrsPoi" : "notAllowed"}`} 
                style={{ gap: "10px" }}
                onClick={() => {
                  if(index < currentStepIndex){
                    onStepChange(key as StepType);
                    Event("STEPPER", `CHANGE_STEP_TO_${key}`, "CLICK_EVENT");
                  }
                  else return;
                }}
              >
                <div 
                  className={`InFlx AlgnItm JstfCnt${currentStepIndex > index ? " StOpcVal" : ""}`}
                  style={{ 
                    height: "44px", 
                    width: "44px", 
                    borderRadius: "50%",
                    border: `3px solid ${currentStepIndex >= index ? "#0B78EA" : "#B3B7BD"}`,
                    backgroundColor: currentStepIndex > index ? "#0B78EA" : "transparent",
                    scale: currentStepIndex === index ? "1.175" : "1",
                  }}
                >
                  <Icon 
                    height={22}
                    width={22} 
                    fill={
                      currentStepIndex === index 
                      ? "#0B78EA" : currentStepIndex > index 
                      ? "#FFF" : "#B3B7BD"
                    }
                  />
                </div>
                <h4 
                  className="DlMg"
                  style={{ color: `${currentStepIndex >= index ? "#0B78EA" : "#B3B7BD"}` }}
                >
                  {steps[key as StepType].text}
                </h4>
              </div>
              {
                !isLastStep(index)
                &&
                <div 
                  className="FlWd"
                  style={{ 
                    maxWidth: "300px",
                    borderBottom: `3px dashed ${currentStepIndex > index ? "#0B78EA" : "#B3B7BD"}` 
                  }}
                />
              }
            </>
          )
        })
      }
    </div>
  );
}
 
export default Stepper;