import React ,{ useEffect, useState } from "react";

interface MainCardSlideProps {
  title: string,
  count: number,
  text: string,
  handler: () => void,
  icon: React.ReactElement
}

const MainCardSlide = ({title, count, text, handler, icon}: MainCardSlideProps) => {

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true)
  }, [])

  return (
    <div 
      className="InFlx align-items-start dashboard-main-card-item spcBtwn" 
      style={{ gap: "6px", padding: "16px 24px", opacity: `${isMounted ? 1 : 0}` }}
      onClick={handler}
    >
      <div className="InFlx Stclmnf align-items-start" style={{ gap: "6px" }}>
        <h3 className="DlMg dashboard-main-card-title">{title}</h3>
        <h1 className="DlMg dashboard-main-card-number">{count}</h1>
        <h5 className="DlMg dashboard-main-card-info-text StOpcVal">{text}</h5>
      </div>
      <div>{icon}</div>
    </div>
  )
}
 
export default MainCardSlide;