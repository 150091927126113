import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Button from '../../../_Common/Button';
import { Translate } from '../../../../utils/lang/translate';
import ToggleSwitch from '../../../_Common/ToggleSwitch';
import CustomNumberInput from '../../_Common/CustomNumberInput/CustomNumberInput';
import { Event } from '../../_Common';

interface ProductFieldError { 
  quantity: string | null, 
  product_name: string | null,
};

type OrderProduct = { 
  product_id: string, 
  quantity: number, 
  logistical_description: string, 
  is_exchange: boolean, 
  defective: boolean, 
  is_refund: boolean 
};

interface RefundProductsFormProps {
  dtStoreinfo: any,
  onFormSubmit: (data: { products: OrderProduct[] }) => void,
  orderProductsData: { orderProducts: OrderProduct[] | null },
  active: boolean,
};

const RefundProductsForm = ({
  dtStoreinfo,
  onFormSubmit,
  orderProductsData,
  active
}: RefundProductsFormProps) => {

  const INITIAL_PRODUCT_ERROR_STATE: ProductFieldError = {
    product_name: null, 
    quantity: null
  };

  const [productsList, setProductsList] = useState<OrderProduct[]>([]);
  const [productsListErrors, setProductsListErrors] = useState<ProductFieldError[]>([]);
  
  const handleQuantityChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    Event("REFUND_PRODUCTS_FORM", "CHANGE_PRODUCT_QUANTITY", "CHANGE_EVENT");
    let new_data = [...productsList.map(p => ({...p}))];
    let new_value = parseInt(e.target.value); 
    new_data[index].quantity = new_value;
    if(new_value !== 0) {
      new_data[index].is_refund = true;  
      if(productsListErrors[index].quantity){
        if(isNaN(productsList[index].quantity)) {
          let new_errors = [...productsListErrors];
          new_errors[index].quantity = null;
          setProductsListErrors([...new_errors]);  
        }else {
          setProductsListErrors(prev => prev.map(err => ({ ...err, quantity: null })));
        }
      }
    } 
    setProductsList([...new_data]);
  };

  const handleQuantityIncrement = (index: number) => {
    Event("REFUND_PRODUCTS_FORM", "INCREMENT_PRODUCT_QUANTITY", "CLICK_EVENT");
    let new_data = [...productsList.map(p => ({...p}))];
    new_data[index].quantity = new_data[index].quantity + 1;
    new_data[index].is_refund = true;
    if(
      productsListErrors[index].quantity && 
      !isNaN(productsList[index].quantity)
    ){
      setProductsListErrors(prev => prev.map(err => ({ ...err, quantity: null })));
    }
    setProductsList(new_data);
  };

  const handleQuantityDecrement = (index: number) => {
    Event("REFUND_PRODUCTS_FORM", "DECREMENT_PRODUCT_QUANTITY", "CLICK_EVENT");
    let new_data = [...productsList.map(p => ({...p}))];
    new_data[index].quantity = new_data[index].quantity - 1;
    setProductsList(new_data);
  }

  const handleDefectiveChange = (index: number) => {
    Event("REFUND_PRODUCTS_FORM", "TOGGLE_PRODUCT_DEFECTIVE", "CLICK_EVENT");
    let new_data = [...productsList];
    new_data[index].defective = !productsList[index].defective;
    setProductsList(new_data);
  };
  
  const handleSubmit = () => {
    Event("REFUND_PRODUCTS_FORM", "FORM_SUBMIT", "CLICK_EVENT");
    let new_errors = productsListErrors;

    if(productsList.every((item, _) => item.quantity === 0)){
      new_errors = [ ...productsListErrors.map(err => ({ ...err, quantity: Translate("error", "quantitycant0") })) ];
    }else {
      productsList.forEach((item, index) => {
        if(isNaN(item.quantity)) new_errors[index].quantity = Translate("error", "reqfield");
      });
    };

    if(new_errors.some((err, _) => err.product_name || err.quantity)){
      Event("REFUND_PRODUCTS_FORM", "FORM_SUBMIT_FAILURE", "CLICK_EVENT");
      setProductsListErrors([...new_errors]);
    }else {
      Event("REFUND_PRODUCTS_FORM", "FORM_SUBMIT_SUCCESS", "CLICK_EVENT");
      onFormSubmit({ 
        products: [ ...productsList.filter(p => p.quantity !== 0).map((item, _) => ({ ...item })) ]
      });
    };
  };

  const disableSubmit = !productsListErrors.every((err, _) => !err.product_name && !err.quantity);

  useEffect(() => {

    const orderProducts = orderProductsData.orderProducts;
    if(orderProducts && productsList.length === 0){
      
      setProductsList(orderProducts.map((item, _) => ({ 
        product_id: item.product_id, 
        logistical_description: item.logistical_description, 
        quantity: 0,
        is_exchange: item.is_exchange,
        defective: item.defective,
        exchange_reason: null,
        is_refund: item.is_refund 
      })));

      setProductsListErrors(
        orderProducts.map((_, __) => ({ ...INITIAL_PRODUCT_ERROR_STATE }))
      );
    }

  }, [orderProductsData.orderProducts]);

  return (
    <form 
      className="InFlx Stclmnf" 
      style={{ gap: "30px", display: `${active ? "flex" : "none"}` }}
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <div className="InFlx Stclmnf" style={{ gap: "40px" }}>
        <h3 className="DlMg">{Translate("orders", "orderinfo")}</h3>
        {
          productsList.map((productListItem, index) => (
            <div 
              className="InFlx AlgnItm spcBtwn flex-wrap StBrdRd" 
              style={{ 
                gap: "20px", 
                border: "1px dashed #B3B7BD",
                padding: "12px" 
              }}
            >
              <div 
                className="InFlx AlgnItm" 
                style={{ gap: "12px", flex: "1 300px" }}
              >
                <h2 className="DlMg">#{index + 1}</h2>
                <div className="InFlx Stclmnf align-items-start">
                  <h2 className="DlMg">{productListItem.logistical_description}</h2>
                  <p className="DlMg">{`${Translate("exchanges", "deliveredqty")}: ${orderProductsData?.orderProducts?.[index].quantity ?? 0}`}</p>
                </div>
              </div>
              <CustomNumberInput
                name={"product-return-quantity-" + index}
                placeholder={Translate("orders", "quantity")}
                error={productsListErrors[index].quantity}
                label={Translate("orders", "quantity")}
                value={productListItem.quantity}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleQuantityChange(e, index)}
                increment={() => handleQuantityIncrement(index)}
                decrement={() => handleQuantityDecrement(index)}
                containerStyles={{ flex: "1 300px" }}
              />
              {
                dtStoreinfo.stock_managed
                &&
                <div 
                  className="InFlx Stclmnf align-items-start" 
                  style={{ flex: "1 300px" }}
                >
                  <p className="StSizLn DlMg"> {Translate("exchanges", "productstat")} </p>
                  <div className="InFlx AlgnItm" style={{ gap: "10px" }}>
                    <ToggleSwitch isOn={productsList[index].defective} toggleHandler={() => handleDefectiveChange(index)}/>
                    <div className="InFlx Stclmnf">
                      <p className="DlMg">{Translate("exchanges", "productdefective")}</p>
                      <p className="DlMg StOpcVal">{Translate("exchanges", "productdefectiveseparat")}</p>
                    </div>
                  </div>
                </div>
              }
            </div>
          ))
        }
      </div>
      <div className="FlWd" style={{ height: "1px", backgroundColor: "var(--fntClr)", opacity: 0.2 }}/>
      <div className="MrAot" style={{ width: "300px" }}>
        <Button
          gray={disableSubmit}
          disabled={disableSubmit} 
          type="submit"
          BtnText={Translate("exchanges", "next")} 
          style={{ padding: "10px 0" }} 
        />
      </div>
    </form>
  );
};
 
function mapState(state: any) {
  
  const { dtStoreinfo } = state.user;
  
  return {
    dtStoreinfo,
  }
};

const actionCreators = {};

const connectedRefundProductsForm = connect(mapState, actionCreators)(RefundProductsForm);
 
export { connectedRefundProductsForm as RefundProductsForm };