import React, { ChangeEvent, useEffect, useState } from 'react';
import { alertActions, productActions } from '../../../../actions';
import { connect } from 'react-redux';
import { ProductInterface, ProductType, RequestStatus } from '../../../../types';
import Button from '../../../_Common/Button';
import { Translate } from '../../../../utils/lang/translate';
import ToggleSwitch from '../../../_Common/ToggleSwitch';
import { Select } from '../../_Common';
import ExchangeSvg from '../../../../svgs/Exchange';
import { CloseSvg2 } from '../../_Common/IconSvg';
import CustomNumberInput from '../../_Common/CustomNumberInput/CustomNumberInput';
import { Event } from '../../_Common';

interface ProductFieldError { 
  quantity: string | null, 
  product_name: string | null,
};

type OrderProduct = { product_id: string, quantity: number, logistical_description: string, is_exchange: boolean, defective: boolean };

interface ExchangeProductsFormProps {
  active: boolean,
  GettingAllProducts: RequestStatus,
  productsData: {
    list: ProductType[],
  },
  dtStoreinfo: any,
  GetAllProductsV2: () => void,
  SendAlert: (code: string, text: string, action: string) => void,
  onFormSubmit: (data: { products: OrderProduct[] }) => void,
  orderProductsData: { orderProducts: OrderProduct[] | null },
};

const ExchangeProductsForm = ({
  GetAllProductsV2,
  GettingAllProducts,
  SendAlert,
  productsData,
  dtStoreinfo,
  onFormSubmit,
  orderProductsData,
  active
}: ExchangeProductsFormProps) => {

  const INITIAL_PRODUCT: OrderProduct = {
    product_id: "",
    logistical_description: "",
    quantity: 0,
    is_exchange: false,
    defective: false,
  };

  const INITIAL_PRODUCT_ERROR_STATE: ProductFieldError = {
    product_name: null, 
    quantity: null
  };

  const [productsList, setProductsList] = useState<OrderProduct[]>([]);
  const [productsListErrors, setProductsListErrors] = useState<ProductFieldError[]>([]);
  const [replaceProducts, setReplaceProducts] = useState<(OrderProduct | null)[]>([]);
  const [replaceProductsErrors, setReplaceProductsErrors] = useState<(ProductFieldError | null)[]>([]);
  const [extraProducts, setExtraProducts] = useState<OrderProduct[]>([]);
  const [extraProductsErrors, setExtraProductsErrors] = useState<ProductFieldError[]>([]);
  
  const handleQuantityChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {

    Event("EXCHANGE_PRODUCTS_FORM", "CHANGE_PRODUCT_QUANTITY", "CHANGE_EVENT");

    let new_data = [...productsList.map(p => ({ ...p }))];
    let new_value = parseInt(e.target.value); 
    new_data[index].quantity = new_value;
    if(new_value !== 0) {
      new_data[index].is_exchange = true;  
      if(productsListErrors[index].quantity){
        if(isNaN(productsList[index].quantity)) {
          let new_errors = [...productsListErrors];
          new_errors[index].quantity = null;
          setProductsListErrors([...new_errors]);  
        }else {
          setProductsListErrors(prev => prev.map(err => ({ ...err, quantity: null })));
        }
      }
    } 
    setProductsList(new_data);
  };

  const handleQuantityIncrement = (index: number) => {

    Event("EXCHANGE_PRODUCTS_FORM", "INCREMENT_PRODUCT_QUANTITY", "CLICK_EVENT");

    let new_data = [...productsList.map(p => ({ ...p }))];
    new_data[index].quantity = new_data[index].quantity + 1;
    new_data[index].is_exchange = true;
    if(
      productsListErrors[index].quantity 
      && !isNaN(productsList[index].quantity)
    ){
      setProductsListErrors(prev => prev.map(err => ({ ...err, quantity: null })));
    }
    setProductsList(new_data);
  };

  const handleQuantityDecrement = (index: number) => {
    Event("EXCHANGE_PRODUCTS_FORM", "DECREMENT_PRODUCT_QUANTITY", "CLICK_EVENT");
    let new_data = [...productsList.map(p => ({ ...p }))];
    new_data[index].quantity = new_data[index].quantity - 1;
    setProductsList(new_data);
  };

  const handleDefectiveChange = (index: number) => {
    Event("EXCHANGE_PRODUCTS_FORM", "TOGGLE_PRODUCT_DEFECTIVE", "CLICK_EVENT");
    let new_data = [...productsList];
    new_data[index].defective = !productsList[index].defective;
    setProductsList(new_data);
  };

  const toggleReplace = (index: number) => {
  
    Event("EXCHANGE_PRODUCTS_FORM", "TOGGLE_PRODUCT_REPLACE", "CLICK_EVENT");

    let new_data = [...replaceProducts];
    let new_errors = [...replaceProductsErrors];

    if(replaceProducts[index] !== null){
      new_data[index] = null;
      new_errors[index] = null;
    }else{
      new_data[index] = { ...INITIAL_PRODUCT };
      new_errors[index] = { ...INITIAL_PRODUCT_ERROR_STATE };
    };

    setReplaceProducts(new_data); 
    setReplaceProductsErrors(new_errors);
  };

  const handleReplaceQuantityChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {

    Event("EXCHANGE_PRODUCTS_FORM", "CHANGE_REPLACE_PRODUCT_QUANTITY", "CHANGE_EVENT");

    let new_data = [...replaceProducts];
    let new_value = parseInt(e.target.value); 
    (new_data[index] as OrderProduct).quantity = new_value;
    if(new_value !== 0 && replaceProductsErrors[index]?.quantity) {
      let new_errors = [...replaceProductsErrors];
      (new_errors[index] as ProductFieldError).quantity = null;
      setReplaceProductsErrors([...new_errors]);
    } 
    setReplaceProducts([...new_data]);
  };

  const handleReplaceQuantityIncrement = (index: number) => {
    Event("EXCHANGE_PRODUCTS_FORM", "INCREMENT_REPLACE_PRODUCT_QUANTITY", "CLICK_EVENT");

    let new_data = [...replaceProducts];
    (new_data[index] as OrderProduct).quantity += 1;
    if(replaceProductsErrors[index]?.quantity) {
      let new_errors = [...replaceProductsErrors];
      (new_errors[index] as ProductFieldError).quantity = null;
      setReplaceProductsErrors([...new_errors]);
    }
    setReplaceProducts(new_data); 
  };

  const handleReplaceQuantityDecrement = (index: number) => {
    Event("EXCHANGE_PRODUCTS_FORM", "DECREMENT_REPLACE_PRODUCT_QUANTITY", "CLICK_EVENT");

    let new_data = [...replaceProducts];
    (new_data[index] as OrderProduct).quantity -= 1;
    setReplaceProducts(new_data);
  };

  const handleReplaceProductChange = (product: ProductInterface, index: number, text: string) => {
    Event("EXCHANGE_PRODUCTS_FORM", "CHANGE_REPLACE_PRODUCT", "CHANGE_EVENT");
    let new_data = [...replaceProducts];
    if(product) {
      (new_data[index] as OrderProduct).product_id = product.id;
      (new_data[index] as OrderProduct).logistical_description = product.logistical_description;
      if(replaceProductsErrors[index]?.product_name) {
        let new_errors = [...replaceProductsErrors];
        (new_errors[index] as ProductFieldError).product_name = null;
        setReplaceProductsErrors(new_errors);
      }
    }else{
      (new_data[index] as OrderProduct).product_id = "";
      (new_data[index] as OrderProduct).logistical_description = text;
      if(!dtStoreinfo.stock_managed) {
        if(replaceProductsErrors[index]?.product_name) {
          let new_errors = [...replaceProductsErrors];
          (new_errors[index] as ProductFieldError).product_name = null;
          setReplaceProductsErrors([...new_errors]);
        }
      }
    }
    setReplaceProducts([...new_data]);
  }

  const AddExtraProduct = () => {
    Event("EXCHANGE_PRODUCTS_FORM", "ADD_EXTRA_PRODUCT", "CLICK_EVENT");
    setExtraProducts([ ...extraProducts, INITIAL_PRODUCT ]);
    setExtraProductsErrors([ ...extraProductsErrors, INITIAL_PRODUCT_ERROR_STATE ]);
  };

  const handleExtraProductChange = (product: ProductInterface, index: number, text: string) => {
    Event("EXCHANGE_PRODUCTS_FORM", "CHANGE_EXTRA_PRODUCT", "CHANGE_EVENT");
    let new_data = [...extraProducts];
    if(product) {
      new_data[index].product_id = product.id;
      new_data[index].logistical_description = product.logistical_description;
      if(extraProductsErrors[index].product_name) {
        let new_errors = [...extraProductsErrors];
        new_errors[index].product_name = null;
        setExtraProductsErrors(new_errors);
      }
    }else{
      new_data[index].product_id = "";
      new_data[index].logistical_description = text;
      if(!dtStoreinfo.stock_managed) {
        if(extraProductsErrors[index].product_name) {
          let new_errors = [...extraProductsErrors];
          new_errors[index].product_name = null;
          setExtraProductsErrors(new_errors);
        }
      }
    }
    setExtraProducts(new_data);
  };

  const handleExtraQuantityChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    Event("EXCHANGE_PRODUCTS_FORM", "CHANGE_EXTRA_PRODUCT_QUANTITY", "CHANGE_EVENT");
    let new_data = [...extraProducts];
    let new_value = parseInt(e.target.value); 
    new_data[index].quantity = new_value;
    if(new_value !== 0 && extraProductsErrors[index].quantity) {
      let new_errors = [...extraProductsErrors];
      new_errors[index].quantity = null;
      setExtraProductsErrors(new_errors);
    } 
    setExtraProducts(new_data);
  };

  const handleExtraQuantityIncrement = (index: number) => {
    Event("EXCHANGE_PRODUCTS_FORM", "INCREMENT_EXTRA_PRODUCT_QUANTITY", "CLICK_EVENT");
    let new_data = [...extraProducts];
    new_data[index].quantity += 1;
    if(extraProductsErrors[index].quantity){
      let new_errors = [...extraProductsErrors];
      new_errors[index].quantity = null;
      setExtraProductsErrors(new_errors);
    }
    setExtraProducts(new_data);
  };

  const handleExtraQuantityDecrement = (index: number) => {
    Event("EXCHANGE_PRODUCTS_FORM", "DECREMENT_EXTRA_PRODUCT_QUANTITY", "CLICK_EVENT");
    let new_data = [...extraProducts];
    new_data[index].quantity -= 1;
    setExtraProducts(new_data);
  };

  const handleRemoveExtraProduct = (index: number) => {
    
    Event("EXCHANGE_PRODUCTS_FORM", "REMOVE_EXTRA_PRODUCT", "CHANGE_EVENT");
    
    setExtraProducts(extraProducts.filter((_, extraProductIndex) => extraProductIndex !== index));
    setExtraProductsErrors(extraProductsErrors.filter((_, errIndex) => errIndex !== index));
  };

  
  const handleSubmit = () => {

    Event("EXCHANGE_PRODUCTS_FORM", "FORM_SUBMIT", "CLICK_EVENT");

    let new_errors = productsListErrors;
    let new_replace_errors = replaceProductsErrors;
    let new_extra_errors = extraProductsErrors;

    if(productsList.every((item, _) => item.quantity === 0)){
      new_errors = [ ...productsListErrors.map(err => ({ ...err, quantity: Translate("error", "quantitycant0") })) ];
    }else{
      productsList.forEach((item, index) => {
        if(isNaN(item.quantity)) new_errors[index].quantity = Translate("error", "reqfield");
      })
    }

    replaceProducts.forEach((item, index) => {
      if(item){
        
        if(item.product_id === "" && dtStoreinfo.stock_managed) 
          (new_replace_errors[index] as ProductFieldError).product_name = Translate("error", "code5")
        ;
        
        if(item.logistical_description === "" && !dtStoreinfo.stock_managed) 
          (new_replace_errors[index] as ProductFieldError).product_name = Translate("error", "reqfield")
        ;
        
        if(item.quantity === 0 || isNaN(item.quantity)) 
          (new_replace_errors[index] as ProductFieldError).quantity = Translate("error", "quantitycant0")
        ;
      }
    });

    extraProducts.forEach((item, index) => {
      if(item.product_id === "" && dtStoreinfo.stock_managed) new_extra_errors[index].product_name = Translate("error", "code5");
      if(item.logistical_description === "" && !dtStoreinfo.stock_managed) new_extra_errors[index].product_name = Translate("error", "reqfield");
      if(item.quantity === 0 || isNaN(item.quantity)) new_extra_errors[index].quantity = Translate("error", "quantitycant0");
    });

    if(
      new_errors.some((err, _) => err.product_name || err.quantity) || 
      new_replace_errors.some((err, _) => err && (err.product_name || err.quantity)) ||
      new_extra_errors.some((err, _) => err.product_name || err.quantity)
    ){

      Event("EXCHANGE_PRODUCTS_FORM", "FORM_SUBMIT_FAILURE", "CHANGE_EVENT");

      setProductsListErrors([...new_errors]);
      setReplaceProductsErrors([...new_replace_errors]);
    }else {
      Event("EXCHANGE_PRODUCTS_FORM", "FORM_SUBMIT_SUCCESS", "CHANGE_EVENT");
      
      const new_products_list = productsList.filter(p => p.quantity !== 0);

      const new_replace_products = replaceProducts.map(
        (item, index) => (
          productsList[index].is_exchange && !item 
          ? { ...productsList[index], is_exchange: false, defective: false } 
          : item
        )
      ).filter(p => p !== null);

      onFormSubmit({ 
        products: [ 
          ...new_products_list.map((item, _) => ({ ...item })) 
        ]
        .concat([...new_replace_products.map((item, _) => ({ ...(item as OrderProduct) }))])
        .concat([...extraProducts.map((item, _) => ({ ...item }))]),
      });
    };
  };

  const disableSubmit = 
    !productsListErrors.every((err, _) => !err.product_name && !err.quantity) || 
    !replaceProductsErrors.every((err, _) => !err?.product_name && !err?.quantity) ||
    !extraProductsErrors.every((err, _) => !err.product_name && !err.quantity) ||
    GettingAllProducts !== '2' 
  ;

  useEffect(() => {

    const orderProducts = orderProductsData.orderProducts;
    if(orderProducts && productsList.length === 0){
      
      setProductsList(orderProducts.map((item, _) => ({ 
        product_id: item.product_id, 
        logistical_description: item.logistical_description, 
        quantity: 0,
        is_exchange: item.is_exchange,
        defective: item.defective,
        exchange_reason: null 
      })));

      setReplaceProducts(orderProducts.map((_, __) => null));
      setReplaceProductsErrors(orderProducts.map((_, __) => null));
      
      setProductsListErrors(
        orderProducts.map((_, __) => ({ ...INITIAL_PRODUCT_ERROR_STATE }))
      );
    }

  }, [orderProductsData.orderProducts]);

  useEffect(() => {
    
    if(GettingAllProducts === '3') 
    SendAlert("50", "Failed to fetch data!", "");

  }, [GettingAllProducts]);

  useEffect(() => {
    GetAllProductsV2();
  }, []);

  return (
    <form 
      className="InFlx Stclmnf" 
      style={{ gap: "30px", display: `${active ? "flex" : "none"}` }}
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <div className="InFlx Stclmnf" style={{ gap: "40px" }}>
        <h3 className="DlMg">{Translate("orders", "orderinfo")}</h3>
        {
          productsList.map((productListItem, index) => (
            <div 
              key={index} 
              className="InFlx Stclmnf StBrdRd" 
              style={{ border: "1px dashed #B3B7BD" }}
            >
              <div 
                className="InFlx AlgnItm spcBtwn flex-wrap" 
                style={{ gap: "20px", padding: "12px" }}
              >
                <div className="InFlx AlgnItm" style={{ gap: "12px", flex: "1 300px" }}>
                  <h2 className="DlMg">#{index + 1}</h2>
                  <div className="InFlx Stclmnf align-items-start">
                    <h2 className="DlMg">{productListItem.logistical_description}</h2>
                    <p className="DlMg">{`${Translate("exchanges", "deliveredqty")}: ${orderProductsData?.orderProducts?.[index].quantity ?? 0}`}</p>
                  </div>
                </div>
                <CustomNumberInput
                  name={"product_exchange_quantity_" + index}
                  value={productListItem.quantity}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleQuantityChange(e, index)}
                  label={Translate("exchanges","exchangequantity")}
                  placeholder={Translate("exchanges","exchangequantity")}
                  error={productsListErrors[index].quantity}
                  increment={() => handleQuantityIncrement(index)}
                  decrement={() => handleQuantityDecrement(index)}
                  containerStyles={{ flex: "1 300px" }}
                />
                {
                  dtStoreinfo.stock_managed
                  &&
                  <div 
                    className="InFlx Stclmnf align-items-start" 
                    style={{ flex: "1 300px" }}
                  >
                    <p className="StSizLn DlMg"> {Translate("exchanges", "productstat")} </p>
                    <div className="InFlx AlgnItm" style={{ gap: "10px" }}>
                      <ToggleSwitch isOn={productsList[index].defective} toggleHandler={() => handleDefectiveChange(index)}/>
                      <div className="InFlx Stclmnf">
                        <p className="DlMg">{Translate("exchanges", "productdefective")}</p>
                        <p className="DlMg StOpcVal">{Translate("exchanges", "productdefectiveseparat")}</p>
                      </div>
                    </div>
                  </div>
                }
              </div>
              {
                replaceProducts[index] !== null
                &&
                <>
                  <div className="RlPs InFlx FlWd">
                    <hr className="FlWd" style={{ color: "#B3B7BD" }}/>
                    <div 
                      className="StAbs InFlx AlgnItm JstfCnt Zindxsm" 
                      style={{ 
                        padding: "0 5px", 
                        backgroundColor: "var(--defcl)", 
                        left: "24px", 
                        top: "50%", 
                        transform: "translateY(-50%)" 
                      }}
                    >
                      <div 
                        className="InFlx AlgnItm JstfCnt"
                        style={{ 
                          height: "32px",
                          width: "32px", 
                          border: "0.5px solid #B3B7BD",
                          borderRadius: "50%", 
                        }}
                      >
                        <ExchangeSvg height={24} width={24} fill='white'/>
                      </div>
                    </div>
                  </div>
                  <div 
                    key={index} 
                    className="InFlx align-items-start spcBtwn flex-wrap" 
                    style={{ gap: "20px", padding: "12px" }}
                  >
                    <Select
                      label={Translate("product", "productname")}
                      placholder={Translate("product", "searchforproduct")}
                      search={true}
                      Options={
                        (productsData?.list ?? []).filter(
                          (product, _) => !replaceProducts.some(
                            (item, itemIndex) => item !== null && item.product_id === product.id && index !== itemIndex
                          ) && !productsList.some(
                            (item, itemIndex) => item.product_id === product.id && index !== itemIndex
                          )
                        )
                      }
                      fieldShow={"logistical_description"}
                      name={"order-replace-product-name-" + index}
                      loading={GettingAllProducts === '1'}
                      value={(productsData?.list ?? []).find((product, _) => product.id === replaceProducts[index]?.product_id) ?? null}
                      onChange={
                        (option: ProductInterface, text: string) => handleReplaceProductChange(option, index, text)
                      }
                      disabled={GettingAllProducts === "1" || GettingAllProducts === "3"}
                      containerClass={replaceProductsErrors[index]?.product_name ? "borderError" : ""}
                      error={replaceProductsErrors[index]?.product_name ? replaceProductsErrors[index]?.product_name : null}
                      style={{ flex: "1 500px" }}
                    />
                    <CustomNumberInput
                      name={"order-replace-product-quantity-" + index}
                      value={replaceProducts[index]?.quantity ?? 0}
                      placeholder={Translate("orders", "quantity")}
                      label={Translate("orders", "quantity")}
                      onChange={
                        (e: ChangeEvent<HTMLInputElement>) => handleReplaceQuantityChange(e, index)
                      }
                      error={replaceProductsErrors[index]?.quantity}
                      increment={() => handleReplaceQuantityIncrement(index)}
                      decrement={() => handleReplaceQuantityDecrement(index)}
                      containerStyles={{ flex: "1 450px" }}
                    />
                    <div
                      onClick={() => toggleReplace(index)} 
                      className="CrsPoi"
                      style={{ flex: "1 50px" }}
                    >
                      {CloseSvg2}
                    </div>
                  </div>
                </>
              }
              {
                productListItem.quantity > 0 && !replaceProducts[index]
                &&
                <div 
                  onClick={() => toggleReplace(index)}
                  className="InFlx AlgnItm StBle CrsPoi" 
                  style={{ gap: "5px", padding: "12px" }}
                >
                  <p className="DlMg">{Translate("exchanges", "exchangewithanother")}</p>
                  <ExchangeSvg height={25} width={25} fill="#3498db"/>
                </div>
              }
            </div>
          ))
        }
        {
          extraProducts.length > 0
          &&
          <h3 className="DlMg">{Translate("exchanges", "newproducts")}</h3>
        }
        {
          extraProducts.map((extraProduct, index) => (
            <div 
              key={index} 
              className="InFlx align-items-start spcBtwn flex-wrap StBrdRd" 
              style={{ 
                gap: "20px", 
                border: "1px dashed #B3B7BD",
                padding: "12px" 
              }}
            >
              <Select
                label={Translate("product", "productname")}
                placholder={Translate("product", "searchforproduct")}
                search={true}
                Options={
                  (productsData?.list ?? []).filter(
                    (product, _) => !extraProducts.some(
                      (item, itemIndex) => item.product_id === product.id && index !== itemIndex
                    )
                  )
                }
                fieldShow={"logistical_description"}
                name={"order-extra-product-name-" + index}
                loading={GettingAllProducts === '1'}
                value={(productsData?.list ?? []).find((product, _) => product.id === extraProduct.product_id) ?? null}
                onChange={(option: ProductInterface, text: string) => handleExtraProductChange(option, index, text)}
                disabled={GettingAllProducts === "1" || GettingAllProducts === "3"}
                containerClass={extraProductsErrors[index].product_name ? "borderError" : ""}
                error={extraProductsErrors[index].product_name ? extraProductsErrors[index].product_name : null}
                style={{ flex: "1 500px" }}
              />
              <CustomNumberInput
                name={"order-extra-product-quantity-" + index}
                value={extraProducts[index].quantity}
                placeholder={Translate("orders", "quantity")}
                label={Translate("orders", "quantity")}
                onChange={
                  (e: ChangeEvent<HTMLInputElement>) => handleExtraQuantityChange(e, index)
                }
                error={extraProductsErrors[index].quantity}
                increment={() => handleExtraQuantityIncrement(index)}
                decrement={() => handleExtraQuantityDecrement(index)}
                containerStyles={{ flex: "1 450px" }}
              />
              <div
                onClick={() => handleRemoveExtraProduct(index)} 
                className="CrsPoi"
                style={{ flex: "1 50px" }}
              >
                {CloseSvg2}
              </div>
            </div>
          ))
        }
        <div 
          onClick={AddExtraProduct}
          className="InFlx AlgnItm StBle CrsPoi" style={{ gap: "5px" }}
        >
          <p className="DlMg">{Translate("exchanges", "sellanotherproduct")}</p>
          <ExchangeSvg height={25} width={25} fill="#3498db"/>
        </div>
      </div>
      <div className="FlWd" style={{ height: "1px", backgroundColor: "var(--fntClr)", opacity: 0.2 }}/>
      <div className="MrAot" style={{ width: "300px" }}>
        <Button
          gray={disableSubmit}
          disabled={disableSubmit} 
          type="submit"
          BtnText={Translate("exchanges", "next")} 
          style={{ padding: "10px 0" }} 
        />
      </div>
    </form>
  );
};
 
function mapState(state: any) {
  
  const { dtStoreinfo } = state.user;
  const { GettingAllProducts, productsData } = state.product;

  return {
    dtStoreinfo,
    GettingAllProducts,
    productsData
  }
};

const actionCreators = {
  GetAllProductsV2: productActions.GetAllProductsV2,
  SendAlert: alertActions.SendAlert,
};

const connectedExchangeProductsForm = connect(mapState, actionCreators)(ExchangeProductsForm);
 
export { connectedExchangeProductsForm as ExchangeProductsForm };