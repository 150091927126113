import { refundConstants } from "../constants/refund.constants";
import { RequestStatus } from "../types";

interface RefundState {
  GettingRefunds?: RequestStatus,
  refundsData?: any,
};

interface RefundAction {
  type: keyof typeof refundConstants,
  payload: any
};

export function refunds(state: RefundState, action: RefundAction): RefundState {
  switch (action.type) {
    case refundConstants.GET_REFUNDS_REQUEST:
      return {
        ...state,
        GettingRefunds: "1",
      };
    case refundConstants.GET_REFUNDS_SUCCESS:
      return {
        ...state,
        GettingRefunds: "2",
        refundsData: action.payload
      };
    case refundConstants.GET_REFUNDS_FAILURE:
      return {
        ...state,
        GettingRefunds: "3",
        refundsData: action.payload
      }
    default: 
      return {
        ...state
      }
  }
}