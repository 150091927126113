import React, { useEffect, useRef } from 'react';
import TabShow from '../../_Common/TabShow';
import { Event } from '../../_Common';
import GroupeBtn from '../../_Common/GroupeBtn';
import { CloseSvg2 } from '../../_Common/IconSvg';
import OrderCreationModalImage from "../../../../utils/img/order-creation-modal-image.png";
import Button from '../../../_Common/Button';
import { Translate } from '../../../../utils/lang/translate';

interface OrderCreationModalProps {
  onClose: () => void,
}

const OrderCreationModal = ({
  onClose,
}: OrderCreationModalProps) => {

  const modalRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (e: MouseEvent) => {
    if(modalRef.current && !modalRef.current?.contains(e.target as Node)){
      Event("ORDER_CREATION_MODAL", "CLOSE_ORDER_CREATION_MODAL", "CLICK_EVENT");
      onClose();
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => window.removeEventListener('click', handleClickOutside);
  }, []);

  return (
    <TabShow
      darkBg={true}
      noReturn={true}
      noPadding={true}
      style={{
        overflow: "hidden",
        minWidth: "53vw",
        backgroundColor: "var(--defcl)"
      }}
    >
      <div 
        ref={modalRef} 
        className="FlWd modal__content RlPs"
      >
        <div style={{ position: "absolute", top: "10px", right: "20px", zIndex: 10 }}>
          <GroupeBtn 
            data_btn={[
              {
                svgbtn: CloseSvg2,
                type: "Gray",
                action: () => {
                  Event("ORDER_CREATION_MODAL", "CLOSE_ORDER_CREATION_MODAL", "CLICK_EVENT");
                  onClose();
                },
              }
            ]}
          />
        </div>
        <div 
          className="InFlx Stclmnf" 
          style={{ padding: "32px", gap: "14px", alignItems: "flex-start" }}
        >
          <h1 
            className="DlMg" 
            style={{ fontSize: "32px", fontWeight: 700 }}
          >
            {Translate("ordercreationmodal", "title")}
          </h1>
          <p 
            className="DlMg StOpcVal" 
            style={{ textAlign: "start", fontSize: "16px" }}
          >
            {Translate("ordercreationmodal", "paragraph")}
          </p>
          <img 
            className="FlWd Brdblue StBrdRd" 
            style={{ 
              height: "120px", 
              backgroundSize: "cover", 
              backgroundPosition: "center", 
              backgroundRepeat: "no-repeat" 
            }}
            src={OrderCreationModalImage} 
            alt={"..."} 
          />
          <div className="InFlx flex-wrap" style={{ gap: "12px" }}>
            <div 
              className="InFlx Stclmnf StBrdRd" 
              style={{ padding: "20px", gap: "12px", flex: "1 200px", backgroundColor: "var(--bdgclInpT)" }}
            >
              <h3 
                className="DlMg" 
                style={{ fontSize: "22px", fontWeight: 500, textAlign: "start" }}
              >
                {Translate("ordercreationmodal", "item1title")}
              </h3>
              <p 
                className="DlMg StOpcVal" 
                style={{ textAlign: "start", fontSize: "16px" }}
              >
                {Translate("ordercreationmodal", "item1text1")}
              </p>
              <p 
                className="DlMg StOpcVal" 
                style={{ textAlign: "start", fontSize: "16px" }}
              >
                {Translate("ordercreationmodal", "item1text2")}
              </p>
            </div>
            <div 
              className="InFlx Stclmnf StBrdRd" 
              style={{ padding: "20px", gap: "12px", flex: "1 200px", backgroundColor: "var(--bdgclInpT)", }}
            >
              <h3 
                className="DlMg" 
                style={{ fontSize: "22px", fontWeight: 500, textAlign: "start" }}
              >
                {Translate("ordercreationmodal", "item2title")}
              </h3>
              <p 
                className="DlMg StOpcVal" 
                style={{ textAlign: "start", fontSize: "16px" }}
              >
                {Translate("ordercreationmodal", "item2text")}
              </p>
            </div>
            <div 
              className="InFlx Stclmnf StBrdRd" 
              style={{ padding: "20px", gap: "12px", flex: "1 200px", backgroundColor: "var(--bdgclInpT)", }}
            >
              <h3 
                className="DlMg" 
                style={{ fontSize: "22px", fontWeight: 500, textAlign: "start" }}
              >
                {Translate("ordercreationmodal", "item3title")}
              </h3>
              <p 
                className="DlMg StOpcVal" 
                style={{ textAlign: "start", fontSize: "16px" }}
              >
                {Translate("ordercreationmodal", "item3text")}
              </p>
            </div>
            <div 
              className="InFlx Stclmnf StBrdRd" 
              style={{ padding: "20px", gap: "12px", flex: "1 200px", backgroundColor: "var(--bdgclInpT)", }}
            >
              <h3 
                className="DlMg" 
                style={{ fontSize: "22px", fontWeight: 500, textAlign: "start" }}
              >
                {Translate("ordercreationmodal", "item4title")}
              </h3>
              <p 
                className="DlMg StOpcVal" 
                style={{ textAlign: "start", fontSize: "16px" }}
              >
                {Translate("ordercreationmodal", "item4text")}
              </p>
            </div>
          </div>
          <div className="FlWd InFlx align-items-end StFlxStr">
            <Button
              onClick={onClose}
              BtnText={Translate("exchanges", "done")} 
              style={{ padding: "10px 15px" }} 
            />
          </div>
        </div>
      </div>
    </TabShow>
  );
}
 
export default OrderCreationModal;