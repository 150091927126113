export const refundConstants = {
  GET_REFUNDS_REQUEST: "GET_REFUNDS_REQUEST",
  GET_REFUNDS_SUCCESS: "GET_REFUNDS_SUCCESS",
  GET_REFUNDS_FAILURE: "GET_REFUNDS_FAILURE",
};

export const refund_status_list = {
  REFUND_STATUS_CREATED: 4,
  REFUND_STATUS_IN_PROGRESS: 5,
  REFUND_STATUS_COLLECTED_BY_AGENT: 10,
  REFUND_STATUS_IN_HUB: 20,
  REFUND_STATUS_WAITING_TRANSIT: 25,
  REFUND_STATUS_IN_TRANSIT: 29,
  REFUND_STATUS_READY_TO_BE_RETURNED: 33,
  REFUND_STATUS_COLLECTED_BY_STORE: 41,
  REFUND_STATUS_ABORTED: 50,
  REFUND_STATUS_NOT_RECEIVED_FROM_TRANSIT: 53
};