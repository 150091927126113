import React, { useEffect, useState } from 'react';
import ContainerPage from '../_Common/ContainerPage';
import { Translate } from '../../../utils/lang/translate';
import Card from '../_Common/Card/Card';
import { RequestStatus, OrderDetails, UnfOrder2Submit} from '../../../types';
import { alertActions, ordersActions } from '../../../actions';
import { connect } from 'react-redux';
import { Link, RouterChildContext, useHistory, useParams } from 'react-router-dom';
import { ProductsForm } from '../OrdersPage/components/ProductsForm';
import { CustomerInfoForm } from '../OrdersPage/components/CustomerInfoForm';
import { DeliveryDetailsForm } from '../OrdersPage/components/DeliveryDetailsForm';
import Stepper, { StepType } from '../OrdersPage/components/Stepper';
import { Event } from '../_Common';

type ApproveUnfOrderProps = {
  SendAlert: (code: string, text: string, action: string) => void,
  dataProfile: any,
  dtStoreinfo: any,
  UpdinunfOrd: RequestStatus,
  dataUpInfOrd: OrderDetails,
  ClearAddOrder: () => void,
  GettingTempOrder: RequestStatus,
  dataTempOrder: OrderDetails,
  GetTempOrder: (order_id: string) => void,
  UpdateUnfOrder: (data: UnfOrder2Submit, order_id: string) => void,
  OrderUpdating: RequestStatus, 
  ClearUpdateUnfOrderData: () => void
};

const ApproveUnfOrder = ({
  dataProfile,
  dtStoreinfo,
  SendAlert,
  UpdinunfOrd,
  dataUpInfOrd,
  GetTempOrder,
  GettingTempOrder,
  dataTempOrder,
  UpdateUnfOrder,
  ClearUpdateUnfOrderData
}: ApproveUnfOrderProps) => {

  const INITIAL_ORDER: UnfOrder2Submit = {
    commune: null,
    customer_name: null,
    customer_phone: null,
    customer_phone2: null,
    destination_text: null,
    products: [],
    product_price: 0,
    express: false,
    pickup_point: null,
    external_order_id: null,
    note_to_driver: null,
    delivery_type: 1,
    source: 1,
    use_points: false,
    wilaya: null,
    is_exchange: false,
    old_order: null
  }

  const history = useHistory<RouterChildContext['router']['history']>();

  const { orderId } = useParams<{ orderId: string }>();
  
  const [order, setOrder] = useState<UnfOrder2Submit>(INITIAL_ORDER);

  const [currentStep, setCurrentStep] = useState<StepType>("PRODUCT_DETAILS");
  
  useEffect(() => {
    GetTempOrder(orderId);
  }, [orderId])

  useEffect(() => {
    
    if(UpdinunfOrd === "2") {
      SendAlert("41", "Successfuly approved unfulfilled order!", "");
      history.push(`/orders`);
    }
    else if(UpdinunfOrd === "3") {
      SendAlert("50", "Failed to add new order!", "");
    };

  }, [UpdinunfOrd]);

  useEffect(() => {
    if(GettingTempOrder === '2') {
      setOrder({
        commune: parseInt(`${dataTempOrder.commune}`),
        customer_name: dataTempOrder.customer_name,
        customer_phone2: dataTempOrder.customer_phone2,
        customer_phone: dataTempOrder.customer_phone,
        delivery_type: dataTempOrder.delivery_type,
        destination_text: dataTempOrder.destination_text,
        express: dataTempOrder.express ?? false,
        external_order_id: dataTempOrder.external_order_id,
        pickup_point: dataTempOrder.pickup_point,
        note_to_driver: dataTempOrder.note_to_driver,
        products: dataTempOrder.products,
        source: dataTempOrder.source,
        product_price: Number(dataTempOrder.product_price ?? "0"),
        use_points: dataTempOrder.use_points,
        wilaya: parseInt(dataTempOrder.wilaya ?? "0"),
        is_exchange: dataTempOrder.is_exchange,
        old_order: null,
        status: 1,
      });
    };

    if(GettingTempOrder === '3') {
      SendAlert("50", "Failed to fetch unfulfilled order data!", "");
    };

  }, [GettingTempOrder]);

  useEffect(() => {
    return () => ClearUpdateUnfOrderData();
  }, [])

  return (
    <ContainerPage 
      page_title={ 
        <div className="StDrc">
          <Link
            className="StOpcVal"
            to={"/unfulfilled/order"}
          >
            {Translate("titles", "unfulfilledorder")}
          </Link>
          <div className="Inlflx StWdDst">
            {Translate("orders", "flech")}
          </div>
          <div className="Inlflx">
            {Translate("orders", "acceptorder")}
          </div>
          <div className="Inlflx StWdDst">#</div>
          <div className="Inlflx">{dataTempOrder?.display_id ?? ""}</div>
        </div>
      }
    >
      {
        GettingTempOrder === '1'
        ?
          <div className='InFlx AlgnItm JstfCnt' style={{ minHeight: "600px" }}>
            <div className='custom-spinner-loader'/>
          </div>
        :
          <Card style={{ gap: "30px" }}>
            
            <Stepper currentStep={currentStep} onStepChange={setCurrentStep}/>

            {
              currentStep === 'PRODUCT_DETAILS'
              &&
              <ProductsForm 
                active={currentStep === 'PRODUCT_DETAILS'}
                orderProductsData={
                  {
                    orderProducts : order.products.map(
                      (item, _) => ({ 
                        product_id: item.product_id, 
                        logistical_description: item.logistical_description ?? "", 
                        quantity: item.quantity,
                        is_exchange: item.is_exchange 
                      })
                    ),
                    external_order_id: order.external_order_id ?? null
                  }
                }
                onFormSubmit={
                  (data) => {
                    setOrder(prev => ({ ...prev, products: data.products, external_order_id: data.external_order_id }));
                    setCurrentStep('CLIENT_INFO');
                  }
                } 
              />
            }
            {
              currentStep === 'CLIENT_INFO'
              &&
              <CustomerInfoForm
                active={currentStep === 'CLIENT_INFO'}
                onGoback={() => setCurrentStep('PRODUCT_DETAILS')}
                customerInfo={
                  (
                    order.customer_name && 
                    order.customer_phone &&
                    order.wilaya &&
                    order.commune
                  )
                  ?
                  {
                    name: order.customer_name,
                    phone: order.customer_phone,
                    wilaya: order.wilaya,
                    commune: order.commune,
                    second_phone: order.customer_phone2
                  }
                  : null
                }
                onFormSubmit={
                  (data) => {
                    setOrder(prev => ({ 
                      ...prev, 
                      customer_name: data.name, 
                      customer_phone: data.phone,
                      wilaya: data.wilaya,
                      commune: data.commune 
                    }));
                    setCurrentStep('DELIVERY_DETAIL');
                  }
                }
              />
            }
            {
              currentStep === "DELIVERY_DETAIL"
              &&
              <DeliveryDetailsForm
                active={currentStep === 'DELIVERY_DETAIL'}
                onGoback={() => setCurrentStep('CLIENT_INFO')}
                onFormSubmit={(data) => {
                  UpdateUnfOrder(
                    { 
                      ...order, 
                      ...data, 
                      product_price: Number(data.total_price ?? "0") ,
                    }
                    , orderId
                  );
                  Event("APPROVE_UNF_ORDER_PAGE", "APPROVE_UNFULFILLED_ORDER", "CLICK_EVENT");
                }}
                loading={UpdinunfOrd === '1'}
                orderDeliveryDetails={{
                  ...order,
                  wilaya: order.wilaya as number,
                  commune: order.commune as number,
                  total_price: order.product_price.toString()
                }}
              />
            }
          </Card>
      }
    </ContainerPage>
  );
}
 
function mapState(state: any) {

  const { dataProfile, dtStoreinfo } = state.user;
  const { orderData, OrderUpdating, DataUpdateOrder } = state.orders
  const { GettingTempOrder, dataTempOrder, UpdinunfOrd, dataUpInfOrd } = state.unfOrders;
  return {
    dataProfile,
    dtStoreinfo,
    orderData,
    OrderUpdating,
    DataUpdateOrder,
    dataTempOrder,
    GettingTempOrder,
    UpdinunfOrd,
    dataUpInfOrd
  };
}

const actionCreators = {
  SendAlert: alertActions.SendAlert,
  GetTempOrder: ordersActions.GetTempOrder,
  UpdateOrder: ordersActions.UpdateOrder,
  UpdateUnfOrder: ordersActions.UpdateUnfOrder,
  ClearUpdateUnfOrderData: ordersActions.ClearUpdateUnfOrderData,
};

const connectedApproveUnfOrder = connect(mapState, actionCreators)(ApproveUnfOrder);

export { connectedApproveUnfOrder as ApproveUnfOrder };
 
