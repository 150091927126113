import React, { InputHTMLAttributes, useRef } from 'react';

type CustomNumberInputProps =  InputHTMLAttributes<HTMLInputElement> & {
  label?: string,
  error?: string | null,
  increment: () => void,
  decrement: () => void,
  containerStyles?: React.CSSProperties   
}

const CustomNumberInput = (props: CustomNumberInputProps) => {

  const inputRef = useRef<HTMLInputElement | null>(null);

  const incrementHandler = () => {
    props.increment();
    inputRef.current?.focus();
  };

  const decrementHandler = () => {
    props.decrement();
    inputRef.current?.focus();
  };

  return (
    <div className="FlWd InFlx Stclmnf" style={props.containerStyles}>
      <label htmlFor={props.id}>{props.label}</label>
      <div 
        className={`StMrg InFlx FrInp StBrdRdXS lgfSize${props.error ? " borderError" : ""}`} 
        style={{ height: "51px" }}
      >
        <div 
          className={`FlHg InFlx AlgnItm JstfCnt BdgGc StBrdRdXS disabled-select ${props.value === 0 ? "notAllowed" : "CrsPoi"}`} 
          onClick={props.value === 0 ? () => {} : decrementHandler}
          style={{ flex: "0 0 51px" }}
        >
          -
        </div>
        <input 
          ref={inputRef}
          type="number"
          className="stfSize TxtCn" 
          style={{ flexGrow: 1 }} 
          {...props}
        />
        <div 
          className="FlHg InFlx AlgnItm JstfCnt BdgGc StBrdRdXS CrsPoi disabled-select" 
          onClick={incrementHandler}
          style={{ flex: "0 0 51px" }}
        >
          +
        </div>
      </div>
      {props.error && <span className="StRedClr">{props.error}</span>}
    </div>
  );
}
 
export default CustomNumberInput;