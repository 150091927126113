import { refundConstants } from '../constants/refund.constants';
import { RequestService, FunctionService } from '../services';
import Cookies from 'js-cookie'

const token = Cookies.get('auth');

export const RefundActions = {
  GetRefunds,
};

function GetRefunds(filter: string) {
  return (dispatch: any) => {
    dispatch(FunctionService.request(refundConstants.GET_REFUNDS_REQUEST));
    RequestService.GetRequest("stores/refunds/" + filter, {
      Authorization: "Token " + token
    })
    .then((res) => {
      dispatch(FunctionService.succes(res.data, refundConstants.GET_REFUNDS_SUCCESS));
    })
    .catch((err) => {
      dispatch(FunctionService.failure(err.response, refundConstants.GET_REFUNDS_FAILURE))
    });
  }
}