import React, { useRef, useEffect } from "react";
import { 
  Chart, 
  ChartOptions, 
  ChartData,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

interface OrderDeliveryChartProps {
  counts: number[],
  dates: string[]
}

const OrderDeliveryChart = ({ counts, dates }: OrderDeliveryChartProps) => {

  const data: ChartData<"bar"> = {
    datasets: [{
      data: counts,
      backgroundColor: "#18A0FB",
      borderColor: "#18A0FB",
      label: "Delivered orders",
      }],
    labels: dates
  };

  const options: ChartOptions<"bar"> = {
    plugins: {
      legend: { display: false },
      tooltip: {
        backgroundColor: "#323D4E",
        padding: 8,
        displayColors: false,  
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      }
    },
  }

  const barChartRef = useRef<Chart<"bar"> | null>(null);

  useEffect(() => {
    const cb = () => {
      barChartRef.current?.update()
    }
    window.addEventListener('themeChanged', cb)
    return () => window.removeEventListener('themeChanged', cb)
  }, [barChartRef.current]);

  return (
    <div className="order-delivery-chart-wrapper">
      <Bar ref={barChartRef} data={data} options={options} />
    </div>
  );
}
 
export default OrderDeliveryChart;