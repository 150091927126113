import React from 'react';

const PhoneIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="ic:baseline-local-phone">
        <path
          id="Vector"
          d="M7.04099 9.98068C8.36099 12.5748 10.4877 14.6923 13.0818 16.0215L15.0985 14.0048C15.346 13.7573 15.7127 13.6748 16.0335 13.7848C17.0602 14.124 18.1693 14.3073 19.306 14.3073C19.8102 14.3073 20.2227 14.7198 20.2227 15.224V18.4232C20.2227 18.9273 19.8102 19.3398 19.306 19.3398C10.6985 19.3398 3.72266 12.364 3.72266 3.75651C3.72266 3.25234 4.13516 2.83984 4.63932 2.83984H7.84766C8.35182 2.83984 8.76432 3.25234 8.76432 3.75651C8.76432 4.90234 8.94766 6.00234 9.28682 7.02901C9.38766 7.34984 9.31432 7.70734 9.05766 7.96401L7.04099 9.98068Z"
          fill="#008AFF"
        />
      </g>
    </svg>
  );
}
 
export default PhoneIcon;