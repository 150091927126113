import React from 'react';

const CloseIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.667"
      height="11.667"
      viewBox="0 0 11.667 11.667"
      {...props}
    >
      <path
        id="Icon"
        d="M139.667-60.825,138.492-62l-4.658,4.658L129.175-62,128-60.825l4.658,4.658L128-51.508l1.175,1.175,4.658-4.658,4.658,4.658,1.175-1.175-4.658-4.658Z"
        transform="translate(-128 62)"
        fill="#fff"
        fillRule="evenodd"
      />
    </svg>
  );
}
 
export default CloseIcon;