import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import ContainerPage from "../_Common/ContainerPage";
import { CheckSvg, CloseSvg, DisableSvg, EditSvg, EnableSvg, FilterSvg2, FlechDwnSvg, OutSvg, ViewSvg2, addIcon, addSvg2, editSvg2, eye_show_password_Icon } from "../_Common/IconSvg";
import { alertActions } from "../../../actions/alert.actions";
import { connect } from "react-redux";
import { FeatureLoked } from "../_Common/FeatureLoked";
import InlinedElement from "../_Common/InlinedElement";
import { Event } from "../_Common";
import { Translate } from "../../../utils/lang/translate";
import TableLoader from "../_Common/TableLoader";
import { EmptyScreen } from "../_Common/EmptyScreen";
import Table from "../_Common/Table";
import PaginationBar from "../_Common/PaginationBar";
import GroupeBtn from "../_Common/GroupeBtn";
import { permissions, permissionsColors } from "../../../constants/team.constants";
import AddTeamMemberModal from "./AddTeamMemberModal";
import "./TeamsPage.css";
import { teamsActions } from "../../../actions/teams.actions";
import { RequestStatus, TeamData } from "../../../types";
import { NumberParam, StringParam, decodeDelimitedArray, encodeDelimitedArray, encodeQueryParams, useQueryParams } from "use-query-params";
import { stringify } from "query-string";
import NewteamMemberModal from "./NewTeamMemberModal";
import CheckBoxForm from "../../_Common/CheckBoxForm";
import TabShow from "../_Common/TabShow";
import BtnLg from "../_Common/BtnLg";
import { RouterChildContext, useHistory } from "react-router-dom";
import EmptyTeamsPicture from "../../../utils/img/teamsempty.png";
import Cookies from "js-cookie";
import IconButton from "../_Common/IconButton";


const lang = Cookies.get("lang");

interface TeamPageProps{
  dataProfile: any,
  dtStoreinfo: any,
  SendAlert: (code: string, text: string, action: string) => void,
  GettingTeamData: RequestStatus,
  teamData: TeamData,
  GetTeamData: (filter: string) => void,
  AddTeamMember: (data: any, profile_picture_data: FormData | null) => void,
  AddingTeamMember: RequestStatus,
  AddTeamMemberData: any,
  UploadingTeamMemberPic: RequestStatus,
  ToggleTeamMember: (member_id: string, value: boolean) => void,
  DeletingTeamMember: RequestStatus,
  ClearAddDeleteStore: () => void,
  dataToggleTeamMember: any
}

interface PermissionIconProps {
  icon: React.ReactElement,
  color: string
}

const PermissionIcon = ({ icon, color }: PermissionIconProps) => {
  return (
    <div 
      className="InFlx AlgnItm" 
      style={{ padding: "4px", borderRadius: "6px", backgroundColor: color }}
    >
      {icon}
    </div>
  )
};

const CommaArrayParam = {
  encode: (array: (string | null)[] | null | undefined) =>
    encodeDelimitedArray(array, ','),

  decode: (arrayStr: string | (string | null)[] | null | undefined) =>
    decodeDelimitedArray(arrayStr, ',')
};

const TeamsPage = ({ 
  dataProfile,  
  SendAlert,
  GetTeamData,
  GettingTeamData,
  teamData,
  AddTeamMember,
  AddTeamMemberData,
  AddingTeamMember,
  UploadingTeamMemberPic,
  DeletingTeamMember,
  ToggleTeamMember,
  ClearAddDeleteStore,
  dataToggleTeamMember 
}: TeamPageProps) => {

  const history = useHistory<RouterChildContext['router']['history']>();
  
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    permissions: CommaArrayParam,
    full_name_or_username: StringParam,
  });

  const [storeMemberSearch, setStoreMemberSearch] = useState<string>("");
  const [showAddTeamMemberModal, setShowAddTeamMemberModal] = useState<boolean>(false);
  const [showNewMemberModal, setShowNewMemberModal] = useState<boolean>(false);
  const [deleteStoreMemberId, setDeleteStoreMemberId] = useState<{id: string, value: boolean} | null>(null);

  const FilterRef = useRef<HTMLDivElement | null>(null);
  const permissionsFilterRef = useRef<HTMLDivElement | null>(null);

  const ShowFilter = function() {
    Event("TEAMS", "CLICK_FILTER", "CLICK_EVENT");
    let clsList = document.getElementById("Filter_container");
    if (clsList && clsList.classList.value.indexOf("hidElem2") !== -1) {
      clsList.classList.remove("hidElem2");
      document.addEventListener("mousedown", handleClickOutsideFilter);
    } else {
      clsList?.classList.add("hidElem2");
      document.removeEventListener("mousedown", handleClickOutsideFilter);
    }
  }

  const handleClickOutsideFilter = function(event: MouseEvent) {
    if (
      FilterRef &&
      FilterRef.current &&
      !FilterRef.current.contains(event.target as Node)
    ) {
      let clsList = document.getElementById("Filter_container");
      if (clsList && !clsList.classList.value.includes("hidElem2")){
        clsList?.classList.add("hidElem2");
      }
    }
  }

  const ControlFilter = (filterId: string, ref: React.RefObject<HTMLDivElement | null>) => {
    let clsList = document.getElementById(filterId);
    if(clsList){
      if (clsList.classList.value.indexOf("hidElem3") !== -1) {
        clsList.classList.remove("hidElem3");
        clsList.style.maxHeight = "400px";
        document.addEventListener(
          "mousedown", 
          (e) => {
            if (
              ref &&
              ref.current &&
              !ref.current.contains(e.target as Node)
            ) {
              let clsList = document.getElementById(filterId);
              if(clsList && !clsList.classList.value.includes("hidElem3")){
                clsList.style.maxHeight = "0px";
                clsList.classList.add("hidElem3");
              }
            }
          }
        );
      } else {
        clsList.style.maxHeight = "0px";
        clsList.classList.add("hidElem3");
        document.removeEventListener(
          "mousedown", 
          (e) => {
            if (
              ref &&
              ref.current &&
              !ref.current.contains(e.target as Node)
            ) {
              let clsList = document.getElementById(filterId);
              if(clsList && !clsList.classList.value.includes("hidElem3")){
                clsList.style.maxHeight = "0px";
                clsList.classList.add("hidElem3");
              }
            }
          }
        );
      }
    }
  };

  const handleStoreMemberSearch = (e: FormEvent<HTMLFormElement>) => {
    Event("TEAMS", "FILTER_TEAM_MEMBERS_BY_USERNAME_OR_FULLNAME", "CLICK_EVENT");
    e.preventDefault();
    if(query.full_name_or_username) {
      setQuery(prev => ({ ...prev, full_name_or_username: undefined, page: undefined }));
      setStoreMemberSearch("");
    }
    else setQuery(prev => ({ ...prev, full_name_or_username: storeMemberSearch, page: undefined }));
  };

  const handleStoreMemberSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStoreMemberSearch(e.target.value);
  };

  const onChangePage = (pageNumber: number) => {
    setQuery(prev => ({ ...prev, page: pageNumber }));
  };

  const clearFilters = () => {
    Event("TEAMS", "CLEAR_FILTERS", "CLICK_EVENT");
    setQuery(prev => ({ ...prev, permissions: undefined, page: undefined }));
  };

  const onChangePermissions = (permissionId: string) => {
    setQuery(prev => {
      if(prev.permissions){
        if(prev.permissions.includes(permissionId)) {
          if(prev.permissions.length === 1) return { ...prev, permissions: undefined, page: undefined };
          else return { ...prev, permissions: prev.permissions.filter(item => item !== permissionId), page: undefined };
        }
        else return { ...prev, permissions: [...prev.permissions, permissionId], page: undefined }
      }else return { ...prev, permissions: [permissionId], page: undefined };
    });
  }

  useEffect(() => {

    const { page, full_name_or_username, permissions } = query;

    const encodedQuery = encodeQueryParams({
      page: NumberParam,
      full_name_or_username: StringParam,
      permissions: CommaArrayParam
    }, { page, full_name_or_username, permissions });

    GetTeamData(`?${stringify(encodedQuery)}`);
  }, [query.page, query.full_name_or_username, query.permissions]);

  useEffect(() => {

    if(AddingTeamMember === "2") {
      SendAlert("41", "Team member added successfuly", "");
      setShowAddTeamMemberModal(false);
      setShowNewMemberModal(true);
    }
    if(AddingTeamMember === "3") SendAlert("50", "Failed to add team member", "");
    if(GettingTeamData === "3") SendAlert("50", "Failed to get teams data", "");
    if(UploadingTeamMemberPic === "2") SendAlert("41", "team member profile picture uploaded successfuly", "");
    if(UploadingTeamMemberPic === "3") SendAlert("50", "Failed to upload team member profile picture", "");
    if(DeletingTeamMember === "2") SendAlert("41", `Team member ${dataToggleTeamMember.is_enabled ? "enabled" : "disabled"} successfuly`, "");
    if(DeletingTeamMember === "3") SendAlert("50", "Failed to delete team member", "");

    if(DeletingTeamMember === "2" || AddingTeamMember === "2"){
      ClearAddDeleteStore();
      const { page, full_name_or_username, permissions } = query;

      const encodedQuery = encodeQueryParams({
        page: NumberParam,
        full_name_or_username: StringParam,
        permissions: CommaArrayParam
      }, { page, full_name_or_username, permissions });

      GetTeamData(`?${stringify(encodedQuery)}`);
    };

  }, [AddingTeamMember, GettingTeamData, UploadingTeamMemberPic, DeletingTeamMember]);


  return (
    <ContainerPage page_title={Translate("TeamsPage", "storestaff")}>
      {
        dataProfile?.is_store
        ?
          <div>
            <div
              className="InFlx flxDrc StmpB3 StPs zindX2 BdgGc"
              style={{ height: "48px", padding: "7px 0", top: "-20px" }}
            >
              {/* Filters + display_id search */}
              <div role="filter-data" className="FlHg InFlx StwdMaxC flxDrc">
                {/* filters */}
                <div
                  className="InFlx AlgnItm FlHg StMrtg2"
                  ref={FilterRef}
                >
                  <IconButton 
                    clickHandler={ShowFilter} 
                    icon={FilterSvg2} 
                    className="BdgBlcl" 
                  />
                  <div
                    id="Filter_container"
                    className="StAbs Zindxsm StLanNlin StBxSh1 StwdMaxC stTranEs StTpval3 HdOvrfl ClSidTh StPdsml StBgbrds hidElem2"
                    style={{ width: "350px" }}
                  >
                    <div id="Sub_Container_filter">
                      <div style={{ marginTop: "8px" }}>
                        <div className="RlPs">
                          <InlinedElement
                            secnd={
                              <strong>{Translate("orders", "filter")}</strong>
                            }
                            leftElm={
                              <div
                                onClick={ShowFilter}
                                className="InFlx CrsPoi IcnSizSm"
                              >
                                {CloseSvg}
                              </div>
                            }
                            style={{ width: "100%", padding: "0px" }}
                          />
                          {/* clear filters buttons */}
                          {
                            Object.keys(query).filter((key) => {
                              if(key === "page" || key === "full_name_or_username") return false;
                              if(key === "permissions" && !query.permissions ) return false;
                              else return true; 
                            }).length > 0
                            &&
                            <span
                              className="CrsPoi StBle DsBlc StdirLn"
                              onClick={clearFilters}
                            >
                              {Translate("orders", "resetfilter")}
                            </span>
                          }
                          <div
                            ref={permissionsFilterRef}
                            className="BdgClN StBrdRdS StPdsml SmMarS1"
                          >
                            <div
                              className="StHeivl RlPs CrsPoi"
                              onClick={() => ControlFilter("Permissions_Container", permissionsFilterRef)}
                            >
                              <InlinedElement
                                secnd={
                                    <strong>
                                      {Translate("TeamsPage", "permissions")}
                                    </strong>
                                }
                                leftElm={
                                    <div className="InFlx">{FlechDwnSvg}</div>
                                }
                                style={{ width: "100%", padding: "0px" }}
                              />
                            </div>
                            <div
                              id="Permissions_Container"
                              className="hidElem3 stTranEs"
                              style={{ overflowY: "auto" }}
                            >
                              <div>
                                {
                                  Object.values(permissions)
                                  .map((permission, index) => (
                                    <CheckBoxForm
                                      key={index}
                                      id={"S" + permission.id}
                                      name={"S" + permission.id}
                                      text={permission.name}
                                      workfun={() => {
                                        Event("TEAMS", `CLICK_${permission.name}_PERMISSION_FILTER`, "CLICK_EVENT");
                                        onChangePermissions(permission.id);
                                      }}
                                      check={query.permissions?.includes(permission.id)}
                                    />
                                  ))
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* search with store username or full_name */}
                <form onSubmit={handleStoreMemberSearch}>
                  <div className="FlHg StBrdRdS ClSidTh">
                    <InlinedElement
                      style={{ padding: "0" }}
                      secnd={
                        <div>
                          <input
                            type="text"
                            autoComplete="off"
                            onChange={handleStoreMemberSearchChange}
                            name="filterbyid"
                            style={{ width: "300px", maxWidth: "30vw" }}
                            className="StdirLn FntfMin StPdsml BdgTrans DlBrd StSizLn Fntcl"
                            value={query.full_name_or_username ?? storeMemberSearch}
                            placeholder={Translate("TeamsPage", "searchstorestaff")}
                          />
                        </div>
                      }
                      leftElm={
                        <button
                          className="BdgGc DlBrd CrsPoi Inlflx StBgbrd"
                          style={{ padding: "10px" }}
                        >
                          { query.full_name_or_username ? OutSvg : CheckSvg }
                        </button>
                      }
                    />
                  </div>
                </form>
              </div>
              <div style={lang === "ar" ? { marginRight: "auto", width: "48px" } : { marginLeft: "auto", width: "48px" }}>
                <IconButton
                  className="FlHg FlWd InFlx JstfCnt"
                  icon={addIcon}
                  clickHandler={() => {
                    Event("TEAMS", "SHOW_ADD_TEAM_MEMBER_MODAL", "CLICK_EVENT");
                    setShowAddTeamMemberModal(true);
                  }}
                />
              </div>
            </div>

            {/* Selected filters display section */}
            <div className="InFlx flxDrc StmpB3 StOpcVal StFlxWrp2 FlwMax AlgnItm">
            {
              query.permissions && query.permissions.length > 0
              &&
              <>
                <div className="stBdp StSmlS StwdMaxC">
                  {Translate("TeamsPage", "permissions")}
                </div> 
                  {
                    query.permissions?.map((permissionId, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          Event("TEAMS", `CLEAR_PERMISSION_${permissionId}`, "CLICK_EVENT");
                          onChangePermissions(permissionId ?? "");
                        }}
                        className="stBdp CrsPoi SthgMaxC"
                      >
                        <InlinedElement
                          style={{
                            border: "2px solid #3498DB",
                            padding: "3px 6px",
                            backgroundColor: "#3498DB35",
                            borderRadius: "5px",
                          }}
                          secnd={
                            <div className="IcnSiz" style={{ zoom: ".48" }}>
                              {CloseSvg}
                            </div>
                          }
                          first={
                            <div className="StSmlS StwdMaxC">
                              {permissions.find((element, _) => element.id === permissionId)?.name ?? ""}
                            </div>
                          }
                        />
                      </div>
                    ))
                  }
              </>
            }
            </div>

            {
              GettingTeamData === "1"
              ?
                <TableLoader/>
              :
                (
                  GettingTeamData === "2" 
                  && teamData.count === 0
                  && teamData.previous === null
                )
                ?
                  <EmptyScreen
                    titleGlb={Translate("TeamsPage", "nomembers")}
                    TextShow={Translate("TeamsPage", "nomembersyet")}
                    PictShow={EmptyTeamsPicture}
                    actionBtns={
                      [
                        {
                          text: Translate("TeamsPage", "addnewmember"),
                          onClick: () => {
                            Event("TEAMS", "SHOW_ADD_TEAM_MEMBER_MODAL", "CLICK_EVENT");
                            setShowAddTeamMemberModal(true);
                          },
                        }
                      ]
                    }
                  />
                :
                  
                  (
                    GettingTeamData === "2"
                    && (teamData.count > 0 || teamData.previous !== null)
                  )
                  &&
                  <div>
                    <Table
                      thead_elem={[
                        Translate("auths", "fullname"),
                        Translate("auths", "username"),
                        Translate("auths", "email"),
                        Translate("TeamsPage", "permissions"),
                        Translate("product", "action"),
                      ]}
                      tbody_elem={
                        teamData.results.map((member, _) => {

                          const view_permissions = member.permissions.filter((permission, _) => permission.codename.startsWith("view")).map((permission, _) => permissions.find((item, _) => item.id === permission.codename)?.name);
                          const edit_permissions = member.permissions.filter((permission, _) => permission.codename.startsWith("change")).map((permission, _) => permissions.find((item, _) => item.id === permission.codename)?.name);
                          const add_permissions = member.permissions.filter((permission, _) => permission.codename.startsWith("add")).map((permission, _) => permissions.find((item, _) => item.id === permission.codename)?.name);

                          return [
                            <div 
                              className={`InFlx AlgnItm ${member.is_enabled ? "" : "StOpcVal"}`} 
                              style={{ gap: "10px" }}
                            >
                              <div className="HdOvrfl StWdTh StBgbrd ElmSvgrg2">
                                <img
                                  alt={"profile picture"}
                                  className="StWdTh"
                                  src={member.picture ?? "/assets/img/default_user.png"}
                                />
                              </div>
                              <p className="DlMg">{member.full_name}</p>
                            </div>,
                            <div className={`${member.is_enabled ? "" : "StOpcVal"}`}>{member.username}</div>,
                            <div className={`${member.is_enabled ? "" : "StOpcVal"}`}>{member.email}</div>,
                            <div className={`InFlx Stclmnf ${member.is_enabled ? "" : "StOpcVal"}`} style={{ gap: "10px" }}>
                              {
                                view_permissions.length > 0
                                &&
                                <div className="InFlx AlgnItm" style={{ gap: "10px" }}>
                                  <PermissionIcon color={permissionsColors.view} icon={ViewSvg2}/>
                                  <p className="DlMg">
                                    {view_permissions.map((permission, _) => `${permission}, `)}
                                  </p>
                                </div>
                              }
                              {
                                edit_permissions.length > 0
                                &&
                                <div className="InFlx AlgnItm" style={{ gap: "10px" }}>
                                  <PermissionIcon color={permissionsColors.edit} icon={editSvg2}/>
                                  <p className="DlMg">
                                    {edit_permissions.map((permission, _) => `${permission}, `)}
                                  </p>
                                </div>
                              }
                              {
                                add_permissions.length > 0
                                &&
                                <div className="InFlx AlgnItm" style={{ gap: "10px" }}>
                                  <PermissionIcon color={permissionsColors.add} icon={addSvg2}/>
                                  <p className="DlMg">
                                    {add_permissions.map((permission, _) => `${permission}, `)}
                                  </p>
                                </div>
                              }
                            </div>,
                            <GroupeBtn 
                              data_btn={[
                                {
                                  type: "BDgInpc",
                                  action: () => {
                                    Event("TEAMS", "EDIT_TEAM_MEMBER_ACTION", "CLICK_EVENT");
                                    history.push(`/settings/teams/${member.id}`);
                                  },
                                  svgbtn: EditSvg,
                                  tooltip: Translate("TeamsPage", "editstoremember")
                                },
                                {
                                  type: "BDgInpc",
                                  action: () => {
                                    Event("TEAMS", `${member.is_enabled ? "DISABLE" : "ENABLE"}_TEAM_MEMBER_ACTION`, "CLICK_EVENT")
                                    setDeleteStoreMemberId({id: member.id, value: !member.is_enabled});
                                  },
                                  svgbtn: member.is_enabled ? DisableSvg : EnableSvg,
                                  tooltip: member.is_enabled ? Translate("TeamsPage", "disablestoremember") : Translate("TeamsPage", "enablestoremember"),
                                }
                              ]} 
                            />,
                          ]
                        })
                      }
                      stickySecnd={true}
                    />

                    <div role="pagination" className="lytWdp SmMarS1">
                      <div className="InFlx flxDrc FlWd RlPs AlgnItm">
                        <span className="d-large">
                          {
                            Translate("orders", "showing") +
                            " " +
                            ((query.page ?? 1) * 20 - 19) +
                            " - " +
                            (
                              (query.page ?? 1) * 20 < teamData.count ?? 0
                              ? (query.page ?? 1) * 20
                              : teamData.count ?? 0
                            ) +
                            " " +
                            Translate("orders", "of") +
                            " " +
                            teamData.count ?? 0
                          }
                        </span>
                        <PaginationBar
                          className="StAutMr"
                          NbPage={Math.trunc((teamData.count ?? 0) / 20) + 1}
                          currentPage={query.page ?? 1}
                          ChangePage={onChangePage}
                          blockNext={teamData.next}
                          blockPrev={teamData.previous}
                        />
                      </div>
                    </div>
                  </div>
                  
            }

            {
              GettingTeamData === "3"
              &&
              <div>
                failed to get team data
              </div>
            }

            {
              showAddTeamMemberModal
              &&
              <AddTeamMemberModal 
                onClose={() => {
                  Event("TEAMS", "HIDE_ADD_TEAM_MEMBER_MODAL", "CLICK_EVENT");
                  setShowAddTeamMemberModal(false);
                }}
                onAddTeamMember={AddTeamMember}
                loading={AddingTeamMember === "1"}
              />
            }

            {
              (showNewMemberModal && AddTeamMemberData)
              &&
              <NewteamMemberModal
                data={AddTeamMemberData}
                onClose={() => {
                  setShowNewMemberModal(false);
                  Event("TEAMS", "HIDE_NEW_TEAM_MEMBER_MODAL", "CLICK_EVENT");
                }}
              />
            }

            {
              deleteStoreMemberId !== null
              &&
              <TabShow
                tab_title={Translate("profile", "confirmaction")}
                wdth="550px"
                darkBg
                noReturn
              >
                <div className="MrAot" style={{ width: "94%" }}>
                  <p className="StdirLn FlWd DltTpMar">
                    {Translate("TeamsPage", "confirmtoggle")}
                  </p>
                  <div className="InFlx flxDrc StMrtp MrAot StwdMaxC">
                    <div
                      className="StwdMaxC CrsPoi StWdDst"
                      style={{ height: "30px" }}
                      onClick={() => {
                        Event("TEAMS", `CANCEL_${deleteStoreMemberId.value ? "ENABLE" : "DISABLE"}_STORE_MEMBER`, "CLICK_EVENT");
                        setDeleteStoreMemberId(null);
                      }}
                    >
                      <BtnLg
                        text={Translate("profile", "cancel")}
                        classSet={""}
                      />
                    </div>
                    <div
                      className="StwdMaxC CrsPoi StWdDst"
                      style={{ height: "30px" }}
                      onClick={() => {
                        Event("TEAMS", `CONFIRM_${deleteStoreMemberId.value ? "ENABLE" : "DISABLE"}_STORE_MEMBER`, "CLICK_EVENT");
                        ToggleTeamMember(deleteStoreMemberId.id, deleteStoreMemberId.value);
                        setDeleteStoreMemberId(null);
                      }}
                    >
                      <BtnLg
                        text={Translate("shop", "confirm")}
                        classSet={"TxDng"}
                      />
                    </div>
                  </div>
                </div>
              </TabShow>
            }

          </div>
        :
          <FeatureLoked/>
      }
    </ContainerPage>
  );
}


function mapState(state: any) {
  const { dataProfile, dtStoreinfo } = state.user;
  const { 
    GettingTeamData, 
    teamData, 
    AddingTeamMember, 
    AddTeamMemberData, 
    UploadingTeamMemberPic, 
    DeletingTeamMember,
    dataToggleTeamMember 
  } = state.teams;

  return { 
    dataProfile, 
    dtStoreinfo, 
    GettingTeamData, 
    teamData, 
    AddingTeamMember, 
    AddTeamMemberData,
    UploadingTeamMemberPic,
    DeletingTeamMember,
    dataToggleTeamMember 
  };
};

const actionCreators = {
  SendAlert: alertActions.SendAlert,
  GetTeamData: teamsActions.GetTeamData,
  AddTeamMember: teamsActions.AddTeamMember,
  ToggleTeamMember: teamsActions.ToggleTeamMember,
  ClearAddDeleteStore: teamsActions.ClearAddDeleteStore
}

const connectedTeamsPage = connect(mapState, actionCreators)(TeamsPage);
export { connectedTeamsPage as TeamsPage };
