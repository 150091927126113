import React, { Component } from "react";
import { Translate } from "../../../utils/lang/translate";
import {
  userActions,
  commonActions,
  notificationsActions,
} from "../../../actions";
import { connect } from "react-redux";
import {
  NotifSvg,
  CntrlSid,
  SerchSVg,
  FlechDwnSvg,
  AccountSvg,
  LogoutSvg,
  LangSvg,
  FlagFrSvg,
  FlagArSvg,
  FlagEnSvg,
  DarkSvg,
  WhiteSvg,
  CheckSvg,
  NotificationIcons,
  AmbassadorSmallSvg,
  MaystroSvg2
} from "../_Common/IconSvg";
import InlinedElement from "../_Common/InlinedElement";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import Helmet from "react-helmet";
import ProfileLoader from "../_Common/ProfileLoader";
import IconBadge from "../_Common/IconBadge";
import { initGAPg, Event } from "../_Common";
import { is_canary_store } from "../../../utils/canaries";
import tawkTo from "../../../utils/workers";
import Pusher from "pusher-js";
import moment from "moment";
import StarterPermiumImg from "../../../utils/img/starter-premium-img.png";
import GoldImg from "../../../utils/img/gold-pack.png";
import "../../../scss/Navbar.scss";
import "./Navbar.css";

const lang = Cookies.get("lang");

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drop: false,
      dropNotifs: false,
      unredNotifications: [],
      unredCount: 0,
      lang: false,
      crntlang: Cookies.get("lang"),
      crntheme: Cookies.get("theme"),
      nameuser: "",
      profilepic: "",
      namestore: "",
      SdHid: false,
      is_canary: false,
    };

    this.Logout = this.Logout.bind(this);
    this.dropit = this.dropit.bind(this);
    this.DropDownRef = React.createRef();
    this.notifsRef = React.createRef();
    this.CntrlSlide = this.CntrlSlide.bind(this);
    this.handleClickOutsideDrop = this.handleClickOutsideDrop.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const {
      GetingUser,
      dataProfile,
      gtStoreInf,
      dtStoreinfo,
      GettingNotifs,
      dataNotifs,
      UpdatingNotif,
    } = nextProps;
    if (this.props.GetingUser === "0" && GetingUser === "1") {
      this.setState({
        nameuser: dataProfile.full_name,
        is_store: dataProfile.is_store,
        profilepic: !dataProfile.is_store ? dataProfile.picture : "",
      });
    }
    if (this.props.gtStoreInf === "0" && gtStoreInf === "1" && dtStoreinfo) {
      // Init pusher subscription
      try {
        const pusher = new Pusher("8f679481791709b11286", {
          cluster: "eu",
          encrypted: true,
        });

        const channel = pusher.subscribe("stores-" + dtStoreinfo.display_id);

        channel.bind("low-stock", (data) => {
          const { unredNotifications, unredCount } = this.state;
          let bundle = data.message;
          let newNotif = {
            pusher_display_id: bundle?.display_id,
            display_id: bundle?.payload?.display_id,
            id: bundle?.id || "",
            payload: bundle,
            read: false,
            seen: false,
            title: bundle.title,
            type: bundle.type,
            product_name: bundle.product_name,
          };
          unredNotifications.unshift(newNotif);
          this.setState({
            unredNotifications,
            unredCount: unredCount + 1,
          });
        });
        channel.bind("alert", (data) => {
          const { unredNotifications, unredCount } = this.state;
          let bundle = data.message;
          let newNotif = {
            pusher_display_id: bundle?.display_id,
            display_id: bundle?.payload?.display_id,
            id: bundle?.id,
            payload: bundle,
            read: false,
            seen: false,
            title: bundle.title,
            type: bundle.type,
            product_name: bundle.product_name,
          };
          unredNotifications.unshift(newNotif);
          this.setState({
            unredNotifications,
            unredCount: unredCount + 1,
          });
        });
      } catch (error) {}
      if (is_canary_store(dtStoreinfo.id) || dtStoreinfo.stock_managed) {
        this.props.setAsCanary();
      }

      let nameStore = "";
      if (dataProfile?.is_store) {
        this.setState({
          profilepic: dtStoreinfo.picture,
          namestore: dtStoreinfo.name,
        });
        nameStore = dtStoreinfo.name;
      } else {
        this.setState({
          namestore: dtStoreinfo.store_name,
        });
        nameStore = dtStoreinfo.store_name;
      }
      // tawkTo(nameStore)
      tawkTo(dtStoreinfo.name);
    }
    if (
      this.props.GettingNotifs === "1" &&
      GettingNotifs === "2" &&
      dataNotifs.ressuc &&
      dataNotifs.ressuc.results
    ) {

      this.setState({
        unredNotifications: dataNotifs.ressuc.results,
        unredCount:
          this.props.MarkingAsSeen !== "1"
            ? 0
            : dataNotifs.ressuc.results.filter((el) => !el.read).length,
      });
    }
    if (this.props.UpdatingNotif == "1" && UpdatingNotif == "2") {
      this.props.GetNotifications();
    }
  }
  
  Logout() {
    Event("NAV_BAR", "USE_LOGOUT_OPTION", "CLICK_EVENT");
    this.props.Logout();
  }
  dropit(name) {
    let initval = this.state[name];
    Event("NAV_BAR", "USER_DROP_NAV_LIST", "CLICK_EVENT");
    if (initval && (name == "drop" || name == "dropNotifs")) {
      document.removeEventListener("mousedown", this.handleClickOutsideDrop);
    } else {
      document.addEventListener("mousedown", this.handleClickOutsideDrop);
    }
    this.setState({
      [name]: !initval,
    });
  }
  handleClickOutsideDrop(event) {
    if (this.DropDownRef && !this.DropDownRef.current?.contains(event.target)) {
      this.setState({ drop: false });
    }
    if (this.notifsRef && !this.notifsRef.current?.contains(event.target)) {
      this.setState({ dropNotifs: false });
    }
  }
  SetLang(lang) {
    if (lang != this.state.crntlang) {
      Event("NAV_BAR", "USER_CHANGE_LANG_TO_" + lang, "CLICK_EVENT");
      var date = new Date();
      date.setDate(date.getDate() + 365);
      Cookies.set("lang", lang, {
        expires: date,
      });
      window.location.reload();
    }
  }
  SetTheme(theme) {
    Event(
      "NAV_BAR",
      "USE_CHANGE_THEME_BUTTON",
      `CHANGE_TO_${theme == "1" ? "DARK" : "LIGHT"}`
    );
    if (this.state.crntheme != theme) {
      var date = new Date();
      date.setDate(date.getDate() + 365);
      Cookies.set("theme", theme, {
        expires: date,
      });
      this.setState({
        crntheme: theme,
      });
      window.dispatchEvent(new window.Event('themeChanged'))
    }
  }

  CallSearch() {
    Event("NAV_BAR", "USE_SEARCH_AREA", "CLICK_EVENT");
    const { Searching, DataSearch, history } = this.props;
    if (Searching == "1" && DataSearch && DataSearch.filter) {
      history.push("/search" + DataSearch.filter);
    } else {
      history.push("/search?websource=" + window.location.pathname);
    }
  }
  CntrlSlide() {
    Event("NAV_BAR", "USE_HIDE_SIDE_BUTTON", "CLICK_EVENT");
    let crnt = this.state.SdHid;
    this.props.ClearStore("MANAGE_SIDE_BAR");
    this.setState({
      SdHid: !crnt,
    });
  }
  MarkAsRed(notif) {
    Event("NAV_BAR_NOTIFS", "READ_NOTIFICATION_FROM_POPUP", "CLICK_EVENT");
    this.props.MarkAsRead(notif.id);
    if (notif.payload) {
      const payload = JSON.parse(
        notif.payload
          .replace(/: {/g, ":'{")
          .replace(/}}/g, "}'}")
          .replace(/'/g, '"')
      );
      if (payload.title === "Low Stock") {
        this.props.history.push("/products/detail/" + payload.product_id);
      } else if (payload.title === "Order Postponed") {
        this.props.history.push("/unfulfilled/order");
      } else if (payload.title === "Alert") {
        this.props.history.push(
          "/order/detail/" + payload.display_id + "?source=order"
        );
      } else {
        this.props.history.push("/unfulfilled/order/" + payload.display_id);
      }
    }
  }
  MarkAsSeen(notif) {
    Event("NAV_BAR_NOTIFS", "CONSULT_NOTIFICATIONS", "CLICK_EVENT");
    this.props.MarkAllAsSeen();
    this.setState({
      unredCount: 0,
    });
  }
  render() {
    let style;
    if (this.state.crntheme == "0") {
      style = (
        <style>{`:root{
                --checbxbr:#bfbfbf;
                --chekclr:#61616114;
                --bdgclInp:#F1F4F9;
                --bdgclInpTran:#323D4E90;
                --fntClr:#202224;
                --fntClrInv:#ffffff;
                --gcl:#edeef0;
                --defcl:#ffffff;
                --clbxshd:#0000000f;
                --clrbrd:#D8D8D8;
                --bdgclInpT:#F1F4F9;
                --Fnpagnt:#ADB1B8;
                --bdgopct:#0000001f;
                --backgroundGray:#F1F4F9;
                --backgroundGray2:#e2e2e2;
                --backgroundGray3:#F1F4F9;
                --border-dark:#B5B5B5;
                --border-button:#808080;
                --bg-select:#ddd;
                --border-bottom-option:#ddd;
                --hover-bg: rgba(255, 255, 255, 0.6);
                --reset-color-fill: #008AFF;
                --date-picker-bg: #fff;
                --chart-text-color: #fff;
            }`}</style>
      );
    } else {
      style = (
        <style>{`:root{
                --bdgclInp:#323D4E;
                --bdgclInpTran:#F1F4F990;
                --fntClr:#ffffff;
                --fntClrInv:#202224;
                --gcl:#1B2431;
                --defcl:#273142;
                --clbxshd:#0000000f;
                --clrbrd:#CFCFCF1D;
                --bdgclInpT:#323D4E;
                --Fnpagnt:#ADB1B8;
                --bdgopct:#ffffff1f;
                --checbxbr:#F0F0F0;
                --chekclr:#999EA7;
                --backgroundGray:#212b39;
                --backgroundGray2:#212b39;
                --backgroundGray3:##182e46;
                --border-dark:#313a46;
                --border-button:#fff;
                --bg-select:#455164;
                --border-bottom-option:#313d4f;
                --hover-bg: rgba(0, 0, 0, 0.3);
                --reset-color-fill: #1B2431;
                --date-picker-bg: #273142;
                --chart-text-color: #000;
            }`}</style>
      );
    }
    const { dtStoreinfo, dataProfile } = this.props;

    return (
      <div className="lytWdp StHgt ClSidTh StBxSh stbd Zindxsm RlPs Navbar">
        <Helmet>{style}</Helmet>
        <div className="InFlx flxDrc FlHg FlWd RlPs">
          <div className="InFlx flxDrc FlHg StzomBr">
            {this.state.SdHid ? (
              <Helmet>
                <style>{`
                                        @media screen and (max-width: 1200px){ 
                                            .StWdSrc{
                                                width:16px;
                                                padding: 0 10px;
                                            }
                                        }
                                        @media screen and (max-width: 540px){
                                            .HdSmelem{
                                                visibility: hidden;
                                            }
                                        }`}</style>
              </Helmet>
            ) : (
              <Helmet>
                <style>{`
                                @media screen and (max-width: 1200px){
                                     
                                }`}</style>
              </Helmet>
            )}
            <div className="TbDp RlPs FlHg StMrtg sidebar-active-item-decoration">
              <div className="TbCdp RlPs TxtCn VrAlg">
                <div className="IcnSiz CrsPoi" onClick={this.CntrlSlide}>
                  {CntrlSid}
                </div>
              </div>
            </div>
            <div className="TbDp RlPs HdSmelem FlHg d-large">
              <div className="TbCdp RlPs TxtCn VrAlg">
                <div
                  className="StWdSrc StTrns HdOvrfl FrInp StBgbrd CrsPoi"
                  onClick={() => this.CallSearch()}
                  style={{ height: "36px" }}
                >
                  <div className="InFlx flxDrc FlHg AlgnItm">
                    <div className="InFlx StMrtg3">{SerchSVg}</div>
                    <span className="">{Translate("search", "clickto")}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="StAbs StLanNl HdSmelem StTpNl FlHg StzomBr">
            <div className="InFlx flxDrc FlHg">
              <div dir="ltr" className="TbDp RlPs FlHg StPaddingVal StScl">
                <div className="TbCdp RlPs TxtCn VrAlg">
                  <div
                    className="StBgbrd InFlx AlgnItm FrInp RlPs"
                    style={{ height: "35px", padding: "0 3px" }}
                  >
                    <div
                      className="IcnSiz CrsPoi Inlflx StPaddingVal"
                      onClick={() => this.SetTheme("1")}
                    >
                      {DarkSvg}
                    </div>
                    <div
                      className="IcnSiz CrsPoi Inlflx StPaddingVal"
                      onClick={() => this.SetTheme("0")}
                    >
                      {WhiteSvg}
                    </div>
                    <span
                      style={{
                        width: "30px",
                        height: "30px",
                        top: "2.5px",
                        left: this.state.crntheme != "0" ? "5px" : "38px",
                      }}
                      className="stTranEs StAbs StBgbrd StTpNl DsBlc togglecolor"
                    ></span>
                  </div>
                </div>
              </div>

              {/* Notification */}
              <div
                className="RlPs FlHg InFlx AlgnItm StMrtg"
                ref={this.notifsRef}
              >
                <div
                  className="TbCdp RlPs TxtCn VrAlg CrsPoi"
                  onClick={() => {
                    this.dropit("dropNotifs");
                    if(!this.props.GettingNotifs || this.props.GettingNotifs === "3"){
                      this.props.GetNotifications();
                    }
                    if(!this.props.MarkingAsSeen || this.props.MarkingAsSeen === "3") {
                      this.MarkAsSeen();
                    }
                  }}
                >
                  <IconBadge icon={NotifSvg} count={this.state.unredCount} />
                </div>
                <div
                  className={
                    "HdOvrfl StAbs StLanNl sTvlSl StTrns Zindxsm StBgbrds BDgInpc " +
                    (this.state.dropNotifs ? "" : "hidElem")
                  }
                  style={{ minWidth: "300px" }}
                >
                  <div className="RlPs FlWd FlHg">
                    <div className="StBrdBt RlPs" style={{ padding: "5%" }}>
                      <InlinedElement
                        secnd={<div>{Translate("titles", "notifs")}</div>}
                      />
                    </div>

                    <div className="CrsPoi StBrdBt RlPs">
                      {
                        this.props.GettingNotifs === "1"
                        ?
                          <div className="FlWd InFlx Stclmnf" style={{ gap: "14px", padding: "5% 10px" }}>
                            {
                              [0, 1, 2].map(value => (
                                <div 
                                  key={value}
                                  className="FlWd InFlx AlgnItm"
                                  style={{ gap: "15px" }} 
                                >
                                  <div 
                                    className="HdOvrfl animate-pulse"
                                    style={{ 
                                      height: "40px", 
                                      width: "40px", 
                                      borderRadius: "50%", 
                                      backgroundColor: "var(--defcl)" 
                                    }}
                                  />
                                  <div className="InFlx Stclmnf" style={{ gap: "8px" }}>
                                    <div 
                                      className="StBrdRd HdOvrfl animate-pulse"
                                      style={{ 
                                        height: "22px", 
                                        width: "200px", 
                                        backgroundColor: "var(--defcl)" 
                                      }}
                                    />
                                    <div
                                      className="StBrdRd HdOvrfl animate-pulse"
                                      style={{ 
                                        height: "18px", 
                                        width: "160px", 
                                        backgroundColor: "var(--defcl)" 
                                      }}
                                    />
                                  </div>
                                </div>
                              ))
                            }
                          </div>
                        :
                          <>
                            {!this.state.unredNotifications.length && (
                              <div className="TxtCn StOpcVal" style={{ padding: "5% 0" }}>
                                {Translate("titles", "nonotif")}
                              </div>
                            )}
                            {this.state.unredNotifications
                              .slice(0, 5)
                              .map((el, index) => {
                                let notif = {
                                  ...el,
                                  title: Translate(
                                    "notifevents",
                                    el.title.toLowerCase().replace(" ", "")
                                  ),
                                };

                                var time_diff = moment(
                                  notif.date_time || notif.created_at
                                );
                                return (
                                  <div
                                    key={index}
                                    className={
                                      "notif " +
                                      "RlPs FlWd " +
                                      (notif.read ? "" : "Tstatus_31")
                                    }
                                    style={{
                                      minHeight: "45px",
                                      padding: "10px 0",
                                      marginBottom: "1px",
                                    }}
                                    onClick={() => this.MarkAsRed(el)}
                                  >
                                    <div className="notif_container InFlx AlgnItm flxDrc">
                                      <div
                                        style={{ flexGrow: "0.2" }}
                                        className="FlHg InFlx AlgnItm JstfCnt"
                                      >
                                        {/* {notif.type=="STOCK"?NotificationIcons.LowStock:NotificationIcons.Payements} */}
                                        {notif.type == "STOCK"
                                          ? NotificationIcons.LowStock
                                          : notif.type === "ALERT"
                                          ? NotificationIcons?.Alert
                                          : notif.type === "ORDER POSTPONED"
                                          ? NotificationIcons.postponed
                                          : NotificationIcons.Orders}
                                      </div>
                                      <div
                                        style={{ flexGrow: "0.8" }}
                                        className="FlHg InFlx flxDrc"
                                      >
                                        <div
                                          className={
                                            "FlHg FlWd Stclmnf StPdsml " +
                                            (lang == "ar" ? "StFlxStr" : "")
                                          }
                                        >
                                          <div className="notif_body InFlx flxDrc">
                                            <h5 className="DlMg">
                                              {/* {notif?.product_name
                                                ? ` ${notif.title} :`
                                                : ""} */}
                                              {/* {notif?.title.toLowerCase() ==='Alert'.toLowerCase() && 'Alert for order :'} */}
                                            </h5>
                                            <h5 className="DlMg">
                                              {notif.product_name &&
                                                notif.product_name.substr(0, 10)}
                                              {/* {notif?.title.toLowerCase() ==='Alert'.toLowerCase() && (notif?.pusher_display_id ? notif?.pusher_display_id : notif?.payload?.display_id)} */}
                                              {notif?.text ||
                                                (notif?.title.toLowerCase() ===
                                                  "Alert".toLowerCase() &&
                                                  `An Alert raised for order : ${notif?.pusher_display_id}`)}
                                              {/* {notif?.product_name
                                                ? ""
                                                : notif?.text} */}
                                            </h5>
                                          </div>
                                          {/* <div className="InFlx flxDrc"><p className=" DlMg StOpcVal">{FormatDate(notif.date_time)}</p></div> */}
                                          <div className="InFlx flxDrc">
                                            <p className=" DlMg StOpcVal">
                                              {time_diff.fromNow()}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </>
                      }
                    </div>
                    <div
                      className="CrsPoi StBrdBt RlPs TxtCn"
                      style={{ padding: "10px 0" }}
                    >
                      <Link to="/notifications">
                        {Translate("titles", "seeallnotifs")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              {/* Profile picture */}
              <div className="TbDp RlPs FlHg StMrtg">
                <div className="TbCdp RlPs TxtCn VrAlg">
                  <div
                    className="HdOvrfl StWdTh StBgbrd ElmSvgrg2"
                    onClick={() =>
                      Event(
                        "NAV_BAR",
                        "USER_CLICK_IN_PROFILE_PICTURE",
                        "CLICK_EVENT"
                      )
                    }
                  >
                    <img
                      alt={dtStoreinfo?.user_name ?? "Store name"}
                      className="StWdTh"
                      src={
                        dataProfile?.picture
                          ? dataProfile.picture
                          : "/assets/img/default_user.png"
                      }
                    />
                  </div>
                </div>
              </div>

              {/* User name */}
              <div className="TbDp RlPs FlHg StMrtg">
                <div className="TbCdp RlPs TxtCn VrAlg">
                  <div className="InFlx AlgnItm" style={{ gap: "10px" }}>
                    <div className="StdirLn">
                      <div className="stbold">{this.state.nameuser}</div>
                      <div className="StSmlS">{this.state.namestore}</div>
                    </div>
                    {
                      this.props.dataRewards?.tier?.name === "Ambassador"
                      &&
                      <div>
                        {AmbassadorSmallSvg}
                      </div>
                    }
                  </div>
                </div>
              </div>

              {/* Settings dropdown */}
              <div className="TbDp RlPs FlHg" ref={this.DropDownRef}>
                <div
                  className="TbCdp RlPs TxtCn VrAlg"
                  onClick={() => this.dropit("drop")}
                >
                  <div className="IcnSiz CrsPoi StSvgCl StBgbrd FrInp">
                    {FlechDwnSvg}
                  </div>
                </div>
                <div
                  className={
                    "HdOvrfl StAbs StLanNl sTvlSl StTrns Zindxsm StBgbrds BDgInpc " +
                    (this.state.drop ? "" : "hidElem")
                  }
                  style={{ minWidth: "250px" }}
                >
                  <div className="InFlx Stclmnf">
                    <div className="FlWd InFlx Stclmnf">
                      <div 
                        className={`FlWd ${dtStoreinfo.is_gold ? "gold-pack-bg" : "starter-permium-pack-bg"}`} 
                        style={{ 
                          height: "85px", 
                          marginBottom: "-40px"
                        }}
                      >
                        <img className="FlWd FlHg" src={dtStoreinfo.is_gold ? GoldImg : StarterPermiumImg} alt={"..."} />
                      </div>
                      <div className={`StWdpic StBr60 HdOvrfl MrAot ${dtStoreinfo.is_gold ? "gold-pack-border" : "starter-permium-pack-border"}`}>
                        <img
                          className="StWdpic"
                          src={
                            dataProfile?.picture 
                            ? 
                              dataProfile?.picture
                            :
                              "/assets/img/default_user.png"
                          }
                          alt={"..."}
                        />
                      </div>
                      <div className="FlWd InFlx AlgnItm JstfCnt" style={{ gap: "10px", padding: "10px 0" }}>
                        <h3 className="DlMg">{dtStoreinfo?.name ?? "Store name"}</h3>
                        {
                          (!dtStoreinfo.is_gold && !dtStoreinfo.stock_managed)
                          &&
                          <div className="navbar-dropdown-menu-badge starter-pack-badge">Business</div>
                        }
                        {
                          (!dtStoreinfo.is_gold && dtStoreinfo.stock_managed)
                          &&
                          <div className="navbar-dropdown-menu-badge premium-pack-badge">Premium</div>
                        }
                        {
                          dtStoreinfo.is_gold
                          &&
                          <div className="navbar-dropdown-menu-badge gold-pack-badge">Gold</div>
                        }
                      </div>
                    </div>
                    <div className="FlWd InFlx Stclmnf">
                      <div
                        className="CrsPoi StBrdBt"
                        style={{ height: "45px" }}
                      >
                        <Link to="/settings/personal-info">
                          <InlinedElement
                            first={<div className="IcnSiz">{AccountSvg}</div>}
                            secnd={
                              <div>{Translate("profile", "editprofile")}</div>
                            }
                          />
                        </Link>
                      </div>
                      <div className="CrsPoi StBrdBt RlPs language">
                        <div
                          className="RlPs"
                          style={{ height: "45px" }}
                          onClick={() => this.dropit("lang")}
                        >
                          <InlinedElement
                            first={
                              <div className="IcnSiz StZoom">{LangSvg}</div>
                            }
                            secnd={
                              <div>{Translate("profile", "language")}</div>
                            }
                            leftElm={FlechDwnSvg}
                          />
                        </div>
                        <div
                          className={
                            this.state.lang
                              ? "lytwdp2 StTrns"
                              : "HdOvrfl StTrns lytwdp2 hidElem"
                          }
                        >
                          <div
                            className="CrsPoi RlPs"
                            style={{ height: "40px" }}
                            onClick={() => this.SetLang("en")}
                          >
                            <InlinedElement
                              first={<div className="IcnSiz">{FlagEnSvg}</div>}
                              secnd={<div>{Translate("profile", "en")}</div>}
                              leftElm={
                                this.state.crntlang == "en" && (
                                  <div className="InFlx">{CheckSvg}</div>
                                )
                              }
                            />
                          </div>
                          <div
                            className="CrsPoi RlPs"
                            style={{ height: "40px" }}
                            onClick={() => this.SetLang("fr")}
                          >
                            <InlinedElement
                              first={<div className="IcnSiz">{FlagFrSvg}</div>}
                              secnd={<div>{Translate("profile", "fr")}</div>}
                              leftElm={
                                this.state.crntlang == "fr" && (
                                  <div className="InFlx">{CheckSvg}</div>
                                )
                              }
                            />
                          </div>
                          <div
                            className="CrsPoi RlPs"
                            style={{ height: "40px" }}
                            onClick={() => this.SetLang("ar")}
                          >
                            <InlinedElement
                              first={<div className="IcnSiz">{FlagArSvg}</div>}
                              secnd={<div>{Translate("profile", "ar")}</div>}
                              leftElm={
                                this.state.crntlang == "ar" && (
                                  <div className="InFlx">{CheckSvg}</div>
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="CrsPoi StBrdBt"
                        style={{ height: "45px" }}
                        onClick={() => this.props.UpdateShowAboutUsModal(true)}
                      >
                        <InlinedElement
                          first={
                            <div className="IcnSiz StZoom">{MaystroSvg2}</div>
                          }
                          secnd={<div>{Translate("footer", "aboutus")}</div>}
                        />
                      </div>
                      <div
                        className="CrsPoi StBrdBt"
                        style={{ height: "45px" }}
                        onClick={this.Logout}
                      >
                        <InlinedElement
                          first={
                            <div className="IcnSiz StZoom">{LogoutSvg}</div>
                          }
                          secnd={<div>{Translate("profile", "logout")}</div>}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { GetingUser, dataProfile, gtStoreInf, dtStoreinfo } = state.user;
  const { Searching, DataSearch } = state.search;
  const {
    GettingNotifs,
    dataNotifs,
    UpdatingNotif,
    dataUpdateNotif,
    MarkingAsSeen,
  } = state.notifications;

  const { dataRewards } = state.rewards;

  return {
    GetingUser,
    dataProfile,
    Searching,
    DataSearch,
    gtStoreInf,
    dtStoreinfo,
    GettingNotifs,
    dataNotifs,
    UpdatingNotif,
    dataUpdateNotif,
    MarkingAsSeen,
    dataRewards
  };
}

const actionCreators = {
  Logout: userActions.Logout,
  setAsCanary: userActions.setAsCanary,
  ClearStore: commonActions.ClearStore,
  GetNotifications: notificationsActions.GetNotifications,
  MarkAsRead: notificationsActions.MarkNotifAsRead,
  MarkAllAsSeen: notificationsActions.MarkAllAsSeen,
  UpdateShowAboutUsModal: userActions.UpdateShowAboutUsModal,
};

const connectedNavBar = connect(mapState, actionCreators)(NavBar);
export { connectedNavBar as NavBar };
