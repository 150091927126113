import React, { useEffect, useRef, useState } from "react";
import TabShow from "../_Common/TabShow";
import { CloseSvg2, closeIcon, eye_hide_password_Icon, eye_show_password_Icon } from "../_Common/IconSvg";
import { Translate } from "../../../utils/lang/translate";
import BtnLg from "../_Common/BtnLg";
import GroupeForm from "../../_Common/GroupeForm";
import { Select } from "../_Common";
import { permissions } from "../../../constants/team.constants";
import { Event } from "../_Common";

const validateEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

interface AddTeamMemberModalProps{
  onClose: () => void,
  onAddTeamMember: (data: any, profile_picture_data: FormData | null) => void,
  loading: boolean,
};

interface IStoreMemberInfo{
  full_name: string,
  username: string,
  password: string,
  email: string,
  permissions: string[],
  image: string
};

interface ICreateMemberError{
  full_name?: string,
  username?: string,
  password?: string,
  email?: string,
  permissions?: string
}

interface PermissionType {
  id: string;
  name: string;
  icons: React.ReactElement[];
  color: string;
};

const AddTeamMemberModal = ({ onClose, onAddTeamMember, loading }: AddTeamMemberModalProps) => {

  const modalRef = useRef<HTMLDivElement | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [storeMemberInfo, setStoreMemberInfo] = useState<IStoreMemberInfo>({
    email: "",
    full_name: "",
    password: "",
    username: "",
    permissions: [],
    image: ""
  });

  const [error, setError] = useState<ICreateMemberError>({});
  
  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    fileInputRef?.current?.click();
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    Event("TEAMS", "UPLOAD_NEW_TEAM_MEMBER_IMAGE", "CLICK_EVENT");
    const file = (event.target.files ?? [])[0];
    if (file && file.type.substr(0, 5) === "image") {
        const reader = new FileReader();
        reader.onloadend = () => {
          setStoreMemberInfo(prev => ({ ...prev, image: reader.result as string }));
        };
        reader.readAsDataURL(file);
    } else {
      setStoreMemberInfo(prev => ({ ...prev, image: "" }));
    }
  };

  const handleClickOutside = (e: MouseEvent) => {
    if(modalRef.current && !modalRef.current?.contains(e.target as Node)){
      onClose();
    }
  };

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    Event("TEAMS", "ADD_NEW_TEAM_MEMBER_PASSWORD_TOGGLE", "CLICK_EVENT");
    setPasswordShown(prev => !prev);
  }

  const handleSubmit = () => {
    Event("TEAMS", "SUBMIT_NEW_TEAM_MEMBER", "CLICK_EVENT");
    let preventSubmit = false;
    if(!storeMemberInfo.full_name || storeMemberInfo.full_name === "") {
      setError(prev => ({ ...prev, full_name: Translate("TeamsPage", "fullname_err") }));
      preventSubmit = true;
    }
    if(!storeMemberInfo.email || storeMemberInfo.email === "" || !validateEmail(storeMemberInfo.email)) {
      setError(prev => ({ ...prev, email: Translate("TeamsPage", "email_err") }));
      preventSubmit = true;
    }
    if(!storeMemberInfo.username || storeMemberInfo.username === "") {
      setError(prev => ({ ...prev, username: Translate("TeamsPage", "username_err") }));
      preventSubmit = true;
    }
    if(!storeMemberInfo.password || storeMemberInfo.password.length < 8) {
      setError(prev => ({ ...prev, password: Translate("TeamsPage", "password_err") }));
      preventSubmit = true;
    }
    if(!storeMemberInfo.permissions || storeMemberInfo.permissions.length === 0) {
      setError(prev => ({ ...prev, permissions: Translate("TeamsPage", "permissions_err") }));
      preventSubmit = true;
    }
    
    if(preventSubmit) {
      return;
    }else{

      const file = (fileInputRef?.current?.files ?? [])[0];
  
      if(file){
        const formData = new FormData();
        formData.append("profile_picture", file);
        onAddTeamMember({
          ...storeMemberInfo,
          permissions: storeMemberInfo.permissions.map((permissionId, _) => ({ codename: permissionId }))
        }, formData);
  
      }else{
        onAddTeamMember({
          ...storeMemberInfo,
          permissions: storeMemberInfo.permissions.map((permissionId, _) => ({ codename: permissionId }))
        }, null);
      }
    };
  }


  useEffect(() => {
    window.addEventListener('mouseup', handleClickOutside);
    return () => window.removeEventListener('mouseup', handleClickOutside);
  }, []);

  return (
    <TabShow
      darkBg
      noReturn
      noPadding={true}
      style={{
        borderRadius: "8px",
        backgroundColor: "var(--defcl)",
        minWidth: "fit-content"
      }}
    >
      <div 
        ref={modalRef} 
        className="InFlx Stclmnf" 
        style={{ 
          paddingTop: "10px", 
          paddingBottom: "10px", 
          gap: "10px", 
          minWidth: "fit-content" 
        }}
      >
        <div className="InFlx spcBtwn AlgnItm" style={{ padding: "5px 15px" }}>
          <div className="add-team-member-modal-header-text">
            {Translate("TeamsPage", "addnewstaffmember")}
          </div>
          <div
            onClick={onClose} 
            className="InFlx AlgnItm sb4R CrsPoi Gray" 
            style={{ padding: "8px" }}
          >
            {closeIcon}
          </div>
        </div>
        <div className="add-staff-member-modal-divider"/>
        <div className="add-staff-member-modal-main">
          <section className="InFlx Stclmnf align-items-start" style={{ gap: "20px" }}>
            <h2 className="DlMg add-team-member-modal-section-header">{Translate("TeamsPage", "memberinfo")}</h2>
            <div className="InFlx AlgnItm" style={{ gap: "15px" }}>
              <div className="StWdpic StBr60 HdOvrfl MrAot">
                <img
                  className="StWdpic"
                  src={
                    storeMemberInfo.image !== "" && storeMemberInfo.image !== null ? 
                    storeMemberInfo.image : "/assets/img/default_user.png"
                  }
                  alt={"..."}
                />
              </div>
              <div className="InFlx Stclmnf align-items-start" style={{ gap: "5px" }}>
                <h4 className="DlMg add-team-member-modal-upload-text">{Translate("TeamsPage", "memberphoto")}</h4>
                <input type="file" ref={fileInputRef} onChange={handleImageChange} style={{ display: "none" }}/>
                <a href="" className="add-team-member-modal-upload-image" onClick={handleLinkClick}>{Translate("TeamsPage", "uploadimage")}</a>
              </div>
            </div>
            <div className="InFlx Stclmnf" style={{ gap: "10px", minWidth: "375px" }}>
              <GroupeForm
                id={"add-member-full-name"}
                name={"add-member-full-name"}
                placeholder={Translate("auths", "fullname")}
                text={Translate("auths", "fullname")}
                type={"text"}
                value={storeMemberInfo.full_name}
                workfun={(e: React.ChangeEvent) => {
                  const { value } = e.target as HTMLInputElement;
                  setStoreMemberInfo(prev => ({ ...prev, full_name: value }));
                  if(value && value !== ""){
                    setError(prev => { 
                      delete prev.full_name; 
                      return prev; 
                    });
                  }
                }}
                disabled={loading}
                error={error.full_name}
                stclass={error.full_name ? "borderError" : ""}
              />
              <GroupeForm
                id={"add-member-user-name"}
                name={"add-member-user-name"}
                placeholder={Translate("auths", "username")}
                text={Translate("auths", "username")}
                type={"text"}
                value={storeMemberInfo.username}
                workfun={(e: React.ChangeEvent) => {
                  const { value } = e.target as HTMLInputElement;
                  setStoreMemberInfo(prev => ({ ...prev, username: value }));
                  if(value && value !== ""){
                    setError(prev => { 
                      delete prev.username; 
                      return prev 
                    });
                  }
                }}
                disabled={loading}
                error={error.username}
                stclass={error.username ? "borderError" : ""}
              />
              <GroupeForm
                id={"add-member-email"}
                name={"add-member-email"}
                placeholder={Translate("auths", "email")}
                text={Translate("auths", "email")}
                type={"email"}
                value={storeMemberInfo.email}
                workfun={(e: React.ChangeEvent) => {
                  const { value } = e.target as HTMLInputElement;
                  setStoreMemberInfo(prev => ({ ...prev, email: value }));
                  if(value && value !== ""){
                    setError(prev => { 
                      delete prev.email; 
                      return prev; 
                    });
                  }
                }}
                disabled={loading}
                error={error.email}
                stclass={error.email ? "borderError" : ""}
              />
              <GroupeForm
                id={"add-member-password"}
                name={"add-member-password"}
                placeholder={Translate("auths", "password")}
                text={Translate("auths", "password")}
                type={passwordShown ? "text" : "password"}
                value={storeMemberInfo.password}
                workfun={(e: React.ChangeEvent) => {
                  const { value } = e.target as HTMLInputElement;
                  setStoreMemberInfo(prev => ({ ...prev, password: value }));
                  if(value && value !== ""){
                    setError(prev => { 
                      delete prev.password; 
                      return prev; 
                    });
                  }
                }}
                disabled={loading}
                error={error.password}
                stclass={error.password ? "borderError" : ""}
                onClick={togglePassword}
                icon={passwordShown ? eye_show_password_Icon : eye_hide_password_Icon}
              />
            </div>
          </section>
          <section className="InFlx Stclmnf align-items-start" style={{ gap: "20px", minWidth: "375px" }}>
            <h2 className="DlMg add-team-member-modal-section-header">{Translate("TeamsPage", "permissions")}</h2>
            <Select
              label={Translate("TeamsPage", "userisassigned")}
              placholder={Translate("TeamsPage", "permissions")}
              search={false}
              Options={permissions.filter((permission, _) => !storeMemberInfo.permissions.includes(permission.id))}
              fieldShow={"name"}
              name="permissions"
              loading={false}
              value={null}
              onChange={(option: PermissionType, _: string) => {
                setError(prev => { 
                  delete prev.permissions; 
                  return prev; 
                });
                setStoreMemberInfo((prev) => ({
                  ...prev,
                  permissions: [ ...prev.permissions, option.id ]
                }))
              }}
              disabled={loading}
              error={error.permissions}
              containerClass={error.permissions ? "borderError" : ""}
            />
            <div className="FlWd InFlx Stclmnf align-items-start" style={{ gap: "8px" }}>
              {
                storeMemberInfo.permissions.map((permissionId, index) => (
                  <div 
                    key={index}
                    className="FlWd InFlx AlgnItm" 
                    style={{ 
                      padding: "8px", 
                      justifyContent: "space-between",
                      borderRadius: "500px",
                      border: `1px solid ${permissions.find((permission, _) => permission.id === permissionId)?.color}` 
                    }}
                  >
                    <div className="InFlx AlgnItm" style={{ gap: "10px" }}>
                      <div 
                        className="InFlx AlgnItm" 
                        style={{ 
                          backgroundColor: `${permissions.find((permission, _) => permission.id === permissionId)?.color}`,
                          gap: "2px",
                          padding: "1px 3px",
                          borderRadius: "600px" 
                        }}
                      >
                        {permissions.find((permission, _) => permission.id === permissionId)?.icons.map((icon, _) => (icon))}
                      </div>
                      <p className="DlMg">{permissions.find((permission, _) => permission.id === permissionId)?.name}</p>
                    </div>
                    <div 
                      onClick={
                        () => setStoreMemberInfo((prev) => ({ 
                          ...prev, 
                          permissions: prev.permissions.filter((item, _) => item !== permissionId) 
                        }))
                      }
                      className="InFlx AlgnItm JstfCnt CrsPoi"
                    >
                      {CloseSvg2}
                    </div>
                  </div>
                ))
              }
            </div>
          </section>
        </div>
        <div
          className="Inlflx AlgnItm JstfCnt CrsPoi"
          onClick={handleSubmit}
        >
          <BtnLg
            disabled={loading}
            style={{
              display: "flex",
              alignItems: "center",
              padding: "10px 7px"
            }} 
            text={Translate("shop", "add")}
          />
        </div>
      </div>
    </TabShow>
  );
}
 
export default AddTeamMemberModal;