import React from 'react';

interface CustomBadgeProps {
  text: string,
  className?: string,
  bgColor: string,
  borderColor: string
}

const CustomBadge = ({ text, className, bgColor, borderColor }: CustomBadgeProps) => (
  <span
    className={className} 
    style={{ 
      padding: "2px 6px",
      backgroundColor: bgColor, 
      border: `1px solid ${borderColor}`, 
      borderRadius: "5px", 
      fontSize: "13px" 
    }}
  >
    {text}
  </span>
)

export default CustomBadge;